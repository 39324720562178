import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  fetchMessage(accountTextId) {
    const url = environment.api_chat_url + '/message/fetch?account_text_id=' + accountTextId;
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  sendMessage(accountTextId, message, clientId = '') {
    const url = environment.api_chat_url + '/message/send?account_text_id=' + accountTextId;
    return this.httpClient.post(url, {message: message, client_id: clientId}).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  deleteMessage(message) {
    const url = environment.api_chat_url + '/message/delete/' + message.text_id;
    return this.httpClient.post(url, {}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  sendJoinMessage(accountTextId) {
    const url = environment.api_chat_url + '/message/join?account_text_id=' + accountTextId;
    return this.httpClient.post(url, {message: ''}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }
  sendCloseMsg(accountTextId) {
    const url = environment.api_chat_url + '/message/close?account_text_id=' + accountTextId;
    return this.httpClient.post(url, {message: ''}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  sendImageMessage(accountTextId, file, clientId= '') {
    const url = environment.api_chat_url + '/message/send?account_text_id=' + accountTextId;
    const formData = new FormData();
    formData.append('message_type', 'image');
    formData.append('message', '');
    formData.append('file', file);
    formData.append('client_id', clientId);
    return this.httpClient.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });

  }

  getMessage(messageTextId) {
    const url = environment.api_chat_url + '/message/get/' + messageTextId;
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  sendConfig(accountTextId, allowSendImage) {
    const url = environment.api_chat_url + '/message/config';
    return this.httpClient.post(url, {
      account_text_id: accountTextId, allow_send_media: allowSendImage
    }).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }
}
