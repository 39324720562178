import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ticket-summary',
  templateUrl: './ticket-summary.component.html',
  styleUrls: ['./ticket-summary.component.css']
})
export class TicketSummaryComponent implements OnInit {

  @Input() summary = {
    open: 0, doing: 0, paused: 0, completed: 0, closed: 0
  };

  constructor() { }

  ngOnInit() {
  }

}
