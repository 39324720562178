<mat-toolbar class="dv-header">
  <h3 mat-dialog-title>Add CS User</h3>
</mat-toolbar>

<div class="dv-content">
  <mat-drawer-container class="dv-drawer-container">
    <!--    <mat-drawer #filterDrawer [mode]="mobileQuery.matches ? 'over' : 'side'" position="end" class="dv-drawer" fxLayout="column">-->
    <mat-drawer-content class="dv-drawer-content">
      <div *ngIf="loading" class="dv-layout-mat-spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
      </div>
      <form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="dv-drawer-content-data">
        <div style="flex-direction: column;flex: 1 1 auto; overflow: auto">
          <mat-label>Backend User</mat-label>
          <mat-form-field appearance="outline">

            <mat-select (opened)="getData()"  msInfiniteScroll [formControl]="form.controls.user_text_id"
                        placeholder="Type to search backend user" (infiniteScroll)="getNextBatch()"
                        [complete]="isScrollComplete()">
              <app-dv-select-search [formControl]="searchFilter"
                                    placeholderLabel="Search by email, name, phone"></app-dv-select-search>
              <mat-option [value]="null">--</mat-option>
              <mat-option *ngFor="let obj of backendUserData" [value]="obj.item.text_id">
                {{obj.item.email}} - {{obj.item.full_name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.user_text_id.touched && form.controls.user_text_id.hasError('required')"></mat-error>
          </mat-form-field>

          <mat-label>Phone Ext</mat-label>
          <mat-form-field appearance="outline">

            <input matInput [formControl]="form.controls.phone_ext" placeholder="Phone ext">
            <mat-error *ngIf="form.controls.phone_ext.touched && form.controls.phone_ext.hasError('required')">
              Phone ext can not be blank
            </mat-error>
          </mat-form-field>
        </div>


        <mat-toolbar class="dv-drawer-content-footer">
          <div mat-dialog-actions fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" align="end">
            <button mat-raised-button (click)="closeDialog()">Cancel</button>
            <button mat-raised-button color="primary" type="submit">Create</button>
          </div>
        </mat-toolbar>
      </form>
    </mat-drawer-content>
  </mat-drawer-container>

</div>
