import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {environment} from '../../../../environments/environment';
import {CallHistoryService} from '../call-history.service';

@Component({
  selector: 'app-call-history-listen',
  templateUrl: './call-history-listen.component.html',
  styleUrls: ['./call-history-listen.component.css']
})
export class CallHistoryListenComponent implements OnInit {

  @ViewChild('audio') audioElement: ElementRef;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<CallHistoryListenComponent>,
      private service: CallHistoryService
  ) { }

  listenUrl() {
    return `${environment.api_base_url}/customer-care/call-history-listen/${this.data.model.item.id}`;
  }

  ngOnInit() {
    // const audioContext = new AudioContext();
    // const source = audioContext.createBufferSource();
    // source.connect(audioContext.destination);

    this.service.listen(this.data.model).subscribe(audioData => {
      // audioContext.decodeAudioData(audioData, buffer => {
      //   source.buffer = buffer;
      //   source.start();
      // });

      const blob = new Blob([audioData], {type: 'audio/wav'});
      this.audioElement.nativeElement.src = URL.createObjectURL(blob);
      this.audioElement.nativeElement.play();
    });
  }

}
