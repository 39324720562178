// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  base_href: '',

  auth_cookie: 'bidvsitdiva',
  auth_token: 'diva',
  api_base_url: 'https://bidvsit.op.vidiva-dev.com/api',
  api_chat_url: 'https://bidvsit.op.vidiva-dev.com/chat-api',

  google_captcha_site_key: '6LcD2bMUAAAAAETA76P_QbxjdeBzVDowFQglw_f1',
  google_captcha_language: 'en', // USE VIETNAMESE

  dwsConfig: {
    dcmConfig: {
      useLoadBalancer: true,
      loadBalancerUrl: 'https://dws.vidiva-dev.com/dcm/redirect-server/connect-info',
      connectionString: 'ws://35.247.175.123:8090',
      appId: 'b52eeb72c19f423eabbc5f6696a2d4a3',
      autoReconnect: true,
      reconnectInterval: 5000,
      reconnectMaxAttempts: 10,
    }
  },
  firebaseConfig: {
    apiKey: 'AIzaSyD70QWev5mk7pT02W9LA6Cw7F-AaWAuvWw',
    authDomain: 'vidiva-wallet.firebaseapp.com',
    databaseURL: 'https://vidiva-wallet.firebaseio.com',
    projectId: 'vidiva-wallet',
    storageBucket: 'vidiva-wallet.appspot.com',
    messagingSenderId: '372526307267',
    appId: '1:372526307267:web:1dbbfa2efb8a9a3a04cf76',
    measurementId: 'G-LB3J9647M5'
  },
  messagingConfig: {
    getTokenIntervalInSeconds: 20,
    registerTokenIntervalInSeconds: 30,
  }
};
