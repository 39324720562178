import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {TicketFilter} from '../customer-care/filters';
import {tick} from '@angular/core/testing';

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    list(filter: TicketFilter) {
        const url = environment.api_base_url + '/ticket/?' + filter.toFilterString();
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    create(data) {
        const url = environment.api_base_url + '/ticket/';
        return this.httpClient.post(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    patch(id, data) {
        const url = environment.api_base_url + '/ticket/' + id;
        return this.httpClient.patch(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    detail(id) {
        const url = environment.api_base_url + '/ticket/' + id.toString();
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    createCategory(data) {
        const url = environment.api_base_url + '/ticket/category';
        return this.httpClient.post(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    updateCategory(id, data) {
        const url = environment.api_base_url + '/ticket/category/' + id;
        return this.httpClient.put(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }


    getAllUsers() {
        const url = environment.api_base_url +
            '/ticket/all-user';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getAllCustomers(search) {
        const url = environment.api_base_url +
            '/ticket/all-customer?search=' + search;
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }



    getAllTicketCategory() {
        const url = environment.api_base_url +
            '/ticket/category';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getAllCustomerWishes() {
        const url = environment.api_base_url +
            '/ticket/wishes';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    createCustomerWishes(data) {
        const url = environment.api_base_url + '/ticket/wishes';
        return this.httpClient.post(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    createCustomerRequest(data) {
        const url = environment.api_base_url + '/ticket/request';
        return this.httpClient.post(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    updateCustomerRequest(id, data) {
        const url = environment.api_base_url + '/ticket/request/' + id;
        return this.httpClient.put(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getAllCustomerRequest() {
        const url = environment.api_base_url +
            '/ticket/request';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getPublicComments(ticket_text_id) {
        const url = environment.api_base_url +
            '/ticket/comment/' + ticket_text_id + '?type=external';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    getInternalComments(ticket_text_id) {
        const url = environment.api_base_url +
            '/ticket/comment/' + ticket_text_id + '?type=internal';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    postPublicComment(ticket_text_id, content) {
        const url = environment.api_base_url + '/ticket/comment/' + ticket_text_id;
        return this.httpClient.post(url, {content: content, type: 'external'}).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    postInternalComment(ticket_text_id, content) {
        const url = environment.api_base_url + '/ticket/comment/' + ticket_text_id;
        return this.httpClient.post(url, {content: content, type: 'internal'}).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    checkDeleteRequest(id) {
        const url = environment.api_base_url + '/ticket/request/' + id + '/check-delete';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    deleteCustomerRequest(id, request_id) {
        const url = environment.api_base_url + '/ticket/request/' + id + '?request_id=' + request_id;
        return this.httpClient.delete(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    checkDeleteCategory(id) {
        const url = environment.api_base_url + '/ticket/category/' + id + '/check-delete';
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    deleteCategory(id, category_id) {
        const url = environment.api_base_url + '/ticket/category/' + id + '?category_id=' + category_id;
        return this.httpClient.delete(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    deleteComment(id) {
        const url = environment.api_base_url + '/ticket/comment/' + id;
        return this.httpClient.delete(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }
}
