import {Component, Input, OnInit} from '@angular/core';
import {MessageGroup} from '../models';

@Component({
  selector: 'app-received-msg',
  templateUrl: './received-msg.component.html',
  styleUrls: ['./received-msg.component.css']
})
export class ReceivedMsgComponent implements OnInit {

  @Input() group: MessageGroup;
  constructor() { }

  ngOnInit() {
  }

}
