import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnInit,
    QueryList,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {CustomerCareService} from '../customer-care.service';
import {IResponse} from '../../shared/interfaces/response';
import {FormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatChip, MatChipList, MatDialog, MatTableDataSource} from '@angular/material';
import {NotifierService} from 'angular-notifier';
import {Location} from '@angular/common';
import {debounceTime, flatMap, switchMap} from 'rxjs/operators';
import {CreateTicketDialogComponent} from './create-ticket-dialog/create-ticket-dialog.component';
import {of, pipe, Subject} from 'rxjs';
import {ContactHistoryFilter} from '../filters';
import {CreateContactDialogComponent} from './create-contact-dialog/create-contact-dialog.component';
import {DcmClientService} from '../../dws/dcm/dcm-client.service';
import {ChatService} from '../chat-service';
import {HttpEventType} from '@angular/common/http';
import {MessageGroup} from './models';
import {TicketService} from '../ticket-service';
import {MenuBadgeService} from '../../shared/menu-badge.service';

@Component({
    selector: 'app-incoming',
    templateUrl: './incoming.component.html',
    styleUrls: ['./incoming.component.scss']
})
export class IncomingComponent implements OnInit, AfterViewInit {
    @HostBinding('class.dv-list-page') layout = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private customerCareService: CustomerCareService,
        private chatService: ChatService,
        private changeDetectorRef: ChangeDetectorRef,
        private notifier: NotifierService,
        private router: Router,
        private location: Location,
        private createTicketDialog: MatDialog,
        private createContactDialog: MatDialog,
        private dwsClient: DcmClientService,
        private ticketService: TicketService,
        private menuBadgeService: MenuBadgeService,
    ) {
    }

    @ViewChild('accountInput') accountInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('chipList') listChip: MatChipList;

    ticketSummary = [];
    contactHistoryId = null;
    customerId = null;
    tabName = 'basic-info';
    searching = false;
    hideCallInfo = true;
    data: any;
    dataTotalCount = 0;
    loading = false;
    accountFormControl = new FormControl();
    searchContactFormControl = new FormControl();
    // noteFormControl = new FormControl('', {
    //     updateOn: 'blur'
    //   }
    // );
    noteFormControl = new FormControl();
    detail = null;

    accounts = [];
    currentAccount = null;
    filteredProfiles = [];
    recentTickets = [];
    allTags = [];
    messages = [];
    messageGroups = [];

    recentTransactions = [];
    recentTransactionsDataSource = new MatTableDataSource<any>(this.recentTransactions);
    recentTransactionDisplayColumns = [
        'created_at',
        'ref_type',
        'amount',
        'after_balance'
    ];
    filter = new ContactHistoryFilter();
    noteSaving = false;
    noteSaved = false;

    msgText = '';
    clientIds = [];
    onMessageReceivedSubscription = null;

    private randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getListData() {
        this.filter.search = this.searchContactFormControl.value;
        this.customerCareService.getContactHistoryList(this.filter).subscribe((response: IResponse) => {
            if (response.success) {
                this.data = response.data;
                this.dataTotalCount = response.data.pagination.total;
            }
        });
    }

    isSameGroup(message, lastMsg) {
        const msgDate = new Date(message.created_at);
        const lastMgsDate = new Date(lastMsg.created_at);
        if (message.user_type === 'system'
            || lastMsg.user_type === 'system'
            || message.user_type === 'join'
            || lastMsg.user_type === 'join') {
            return false;
        }
        return message.user_id === lastMsg.user_id && message.user_type === lastMsg.user_type &&
            msgDate.getDay() === lastMgsDate.getDay() &&
            msgDate.getMonth() === lastMgsDate.getMonth() &&
            msgDate.getFullYear() === lastMgsDate.getFullYear();
    }

    isSameDate(message, lastMsg) {
        const msgDate = new Date(message.created_at);
        const lastMgsDate = new Date(lastMsg.created_at);
        return msgDate.getDay() === lastMgsDate.getDay() &&
            msgDate.getMonth() === lastMgsDate.getMonth() &&
            msgDate.getFullYear() === lastMgsDate.getFullYear();
    }

    buildGroup() {
        this.messageGroups = [];
        if (this.messages.length === 0) {
            return;
        }

        let lastMsg = this.messages[0].item;
        let currentGroup = new MessageGroup();
        const firstDate = new MessageGroup();
        firstDate.date = lastMsg.created_at;
        firstDate.type = 'date';
        firstDate.userType = 'date';
        this.messageGroups.push(firstDate);

        for (const msg of this.messages) {
            const message = msg.item;
            if (this.isSameGroup(message, lastMsg)) {
                currentGroup.addMessage(message);
            } else {
                this.messageGroups.push(currentGroup);
                currentGroup = new MessageGroup();
                currentGroup.addMessage(message);

                if (!this.isSameDate(message, lastMsg)) {
                    const dateGroup = new MessageGroup();
                    dateGroup.type = 'date';
                    dateGroup.userType = 'date';
                    dateGroup.date = message.created_at;
                    this.messageGroups.push(dateGroup);
                }
            }
            lastMsg = message;
        }
        if (currentGroup.messages.length > 0) {
            this.messageGroups.push(currentGroup);
        }
    }

    fetchMessage() {
        this.chatService.fetchMessage(this.currentAccount.text_id).subscribe((response: IResponse) => {
            if (response.success) {
                this.messages = response.data.items;
                this.clientIds = [];
                this.messages.forEach(msg => {
                    this.clientIds[msg.item.client_id] = 1;
                });
                this.buildGroup();
            } else {
                this.messages = [];
                this.buildGroup();
            }
        });
    }

    getMessage(id) {
        this.chatService.getMessage(id).subscribe((res: IResponse) => {
            if (res.success) {
                this.addMessage(res.data);
            }
        });
    }

    isFullDataLoaded() {
        return this.data === null || this.data.items.length >= this.dataTotalCount;
    }

    loadMoreData() {
        this.filter.search = this.searchContactFormControl.value;
        this.filter.offset = this.data == null ? 0 : this.data.items.length;
        this.customerCareService.getContactHistoryList(this.filter).subscribe((response: IResponse) => {
            if (response.success) {
                if (this.data == null) {
                    this.data = response.data;
                    this.dataTotalCount = response.data.pagination.total;
                } else {
                    this.data.items.push(...response.data.items);
                }
            }
        });
    }

    addMessage(msg) {
        this.messages.push({item: msg});
        if (this.messageGroups.length === 0) {
            this.buildGroup();
        } else {
            const lastGroup = this.messageGroups[this.messageGroups.length - 1];
            if (lastGroup.messages.length > 0) {
                const lastMsg = lastGroup.messages[lastGroup.messages.length - 1];
                if (this.isSameGroup(lastMsg, msg)) {
                    lastGroup.addMessage(msg);
                } else {
                    if (msg.user_type === 'join') {
                        const joinGroup = new MessageGroup();
                        joinGroup.type = 'join';
                        joinGroup.userType = 'join';
                        joinGroup.date = msg.created_at;
                        joinGroup.addMessage(msg);
                        this.messageGroups.push(joinGroup);
                    } else if (msg.user_type === 'system') {
                        const joinGroup = new MessageGroup();
                        joinGroup.type = 'system';
                        joinGroup.userType = 'system';
                        joinGroup.date = msg.created_at;
                        joinGroup.addMessage(msg);
                        this.messageGroups.push(joinGroup);
                    } else if (this.isSameDate(lastMsg, msg)) {
                        const newGroup = new MessageGroup();
                        newGroup.addMessage(msg);
                        this.messageGroups.push(newGroup);
                    } else {
                        const dateGroup = new MessageGroup();
                        dateGroup.type = 'date';
                        dateGroup.userType = 'date';
                        dateGroup.date = msg.created_at;
                        this.messageGroups.push(dateGroup);
                        const newGroup = new MessageGroup();
                        newGroup.addMessage(msg);
                        this.messageGroups.push(newGroup);
                    }
                }
            }
        }
    }

    ngOnInit() {
        this.menuBadgeService.addIncomingBadge(null);
        this.filter.load = localStorage.getItem('cs_load');
        this.filter.ordering = '-updated_at';
        if (!this.filter.load) {
            this.filter.load = 'me';
            localStorage.setItem('cs_load', 'me');
        }
        if (this.onMessageReceivedSubscription == null) {
            this.onMessageReceivedSubscription = this.dwsClient.onMessageReceivedSubject.subscribe(value => {
                value = JSON.parse(value);
                if (value.event === 'cs.incoming') {

                    const text_id = value.message.contact_history_text_id;

                    const history = this.data.items.filter(x => x.item.text_id === text_id);
                    if (history.length > 0) {
                        const item = history[0].item;
                        const index = this.data.items.indexOf(history[0]);
                        if (index !== -1) {
                            this.data.items.splice(index, 1);
                            this.data.items.unshift(history[0]);
                        }
                        if (item.text_id !== this.detail.text_id) {
                            item.calling = true;
                        }
                        item.last_message = value.message.message;
                    } else {
                        this.customerCareService.getContactHistoryDetail(text_id, 'list').subscribe((res: IResponse) => {
                            if (res.success) {
                                const detail = res.data;
                                detail.item.calling = true;
                                this.data.items.unshift(detail);
                            }
                        });
                    }
                } else if (value.event === 'chat.message') {
                    // This code is so slow
                    // const msgId = value.message.message_text_id;
                    // this.getMessage(msgId);
                    const msg = value.message.message_obj;
                    if (value.message.account_text_id === this.currentAccount.text_id && !this.clientIds[msg.client_id]) {
                        this.clientIds[msg.client_id] = msg;
                        this.addMessage(msg);
                    } else if (value.message.account_text_id === this.currentAccount.text_id && this.clientIds[msg.client_id]) {
                        const msgObj = this.clientIds[msg.client_id];
                        msgObj.status = msg.status;
                        msgObj.deleted_at = msg.deleted_at;
                        msgObj.deleted_by_obj = msg.deleted_by_obj;
                    }
                    if (msg.user_type === 'backend_user' || msg.user_type === 'app_user') {
                        const conversation = value.message.conversation;
                        const history = this.data.items.filter(x => x.item.text_id === conversation.cs_history_id);
                        if (history.length > 0) {
                            const item = history[0].item;
                            const index = this.data.items.indexOf(history[0]);
                            if (index !== -1) {
                                this.data.items.splice(index, 1);
                                this.data.items.unshift(history[0]);
                            }
                            if (item.text_id !== this.detail.text_id) {
                                item.calling = true;
                            }
                            item.last_message = value.message.message;
                        }
                    } else if (msg.user_type === 'join') {
                        const conversation = value.message.conversation;
                        for (let i = 0; i < this.data.items.length; i++) {
                            const history = this.data.items[i];
                            if (history.item.text_id === conversation.cs_history_id) {
                                console.log('debug join');
                                console.log(value.message);
                                console.log(history);
                                history.item.assigned = msg.user;
                            }
                        }
                    }

                }
            });
        }
        this.getListData();
        this.activatedRoute.paramMap.subscribe((p: ParamMap) => {
            if (p.has('id')) {
                const contactHistoryId = p.get('id');
                if (this.contactHistoryId !== contactHistoryId) {
                    this.contactHistoryId = contactHistoryId;
                    this.getDetail();
                }
            }

            if (p.has('customer_id')) {
                const customerId = p.get('customer_id');
                if (this.customerId !== customerId) {
                    this.customerId = customerId;
                }
            }

            if (p.has('tab')) {
                const tab = p.get('tab');
                if (this.tabName !== tab) {
                    this.tabName = tab;
                }
            }
        });

        this.accountFormControl.valueChanges.pipe(
            debounceTime(500),
            switchMap(value => this.customerCareService.searchProfiles(value))
        ).subscribe((response: IResponse) => {
            this.filteredProfiles = response.data;
        });

        this.noteFormControl.valueChanges.pipe(
            flatMap(value => {
                this.noteSaved = false;
                this.noteSaving = false;
                return of(value);
            }),
            debounceTime(2000),
            switchMap(value => {
                this.noteSaved = false;
                this.noteSaving = true;
                return this.customerCareService.updateNote(this.contactHistoryId, value);
            })
        ).subscribe((response: IResponse) => {
            if (response.success) {
                this.noteSaved = true;
                this.noteSaving = false;
                this.notifier.notify('success', 'Successfully saved note');
            }
        });

        this.searchContactFormControl.valueChanges.pipe(
            debounceTime(500),
            switchMap(value => {
                this.filter.search = value;
                this.filter.offset = 0;
                this.searching = true;
                return this.customerCareService.getContactHistoryList(this.filter);
            })
        ).subscribe((response: IResponse) => {
            if (response.success) {
                this.data = response.data;
            }
            this.searching = false;
        }, error => {
            this.searching = false;
        });

    }

    markDoneTicket(ticket) {
        this.customerCareService.markDoneTicket(ticket.text_id).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = 'done';
            }
        });

    }

    editTicket(ticket) {
        const dialogRef = this.createTicketDialog.open(CreateTicketDialogComponent, {
            data: {
                account_id: this.currentAccount.text_id,
                ticket: ticket
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                for (const attr in res) {
                    if (res[attr]) {
                        ticket[attr] = res[attr];
                    }
                }
            }
        });
    }

    deleteTicket(ticket) {
        this.customerCareService.deleteTicket(ticket.text_id).subscribe((res: IResponse) => {
            if (res.success) {
                this.customerCareService.recentTickets(this.currentAccount.text_id).subscribe((rs: IResponse) => {
                    if (rs.success) {
                        this.recentTickets = rs.data;
                    }
                });
            }
        });

    }

    getDetail() {
        this.loading = true;
        this.customerCareService.getContactHistoryDetail(this.contactHistoryId).subscribe((response: IResponse) => {
                this.detail = response.data;
                this.allTags = this.detail.tags;
                this.accounts = this.detail.accounts;
                if (this.accounts.length === 0) {
                    this.currentAccount = null;
                }
            },
            err => {
                this.notifier.notify('error', err.error.message);
            },
            () => {
                this.loading = false;
            }
        );
    }

    getProfileContact(profile) {

        if (profile.phone) {
            return profile.phone + ' - ' + profile.name;
        }
        if (profile.mobile_phone) {
            return profile.mobile_phone + ' - ' + profile.name;
        }
        if (profile.email) {
            return profile.email + ' - ' + profile.name;
        }
        return profile.name;
    }

    removeAccount(account) {
        this.customerCareService.removeAccount(account.text_id, this.contactHistoryId).subscribe((response: IResponse) => {
            if (response.success) {
                const index = this.accounts.indexOf(account);

                if (account === this.currentAccount) {
                    this.currentAccount = null;
                }

                if (index >= 0) {
                    this.accounts.splice(index, 1);
                    this.dataTotalCount -= 1;
                }
                this.notifier.notify('success', 'Successfully removed account');
            }
        });
    }

    selectAccount(event) {

        const obj = event.option.value;
        this.accountInput.nativeElement.value = '';
        this.accountFormControl.setValue(null);

        this.customerCareService.addAccount(
            obj.profile.text_id,
            obj.type,
            this.contactHistoryId
        ).subscribe((response: IResponse) => {
            if (response.success) {
                this.accounts.push(response.data);
                this.notifier.notify('success', 'Successfully added account');
            } else {
                this.notifier.notify('error', response.messages.error[0]);
            }
        }, err => {
            this.notifier.notify('error', err.error.message);
        });
    }

    openCreateTicketDialog() {
        const dialogRef = this.createTicketDialog.open(CreateTicketDialogComponent, {
            data: {
                account_id: this.currentAccount.text_id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.recentTickets.unshift(result);
            }
        });
    }

    openCreateContactDialog() {
        const dialogRef = this.createContactDialog.open(CreateContactDialogComponent, {
            data: {
                contact: this.searchContactFormControl.value
            },
            panelClass: 'dv-flex-dialog',
            minWidth: '500px',
            width: '50%',
            minHeight: '400px',
            height: '50%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getListData();
            }
        });
    }

    fetchTicketSummary() {
        this.ticketService.summary(this.currentAccount.text_id).subscribe((res: IResponse) => {
            this.ticketSummary = res.data;
        });
    }


    selectionChange(event) {

        if (event.selected) {
            this.currentAccount = event.source.value;
            this.fetchMessage();
            this.fetchTicketSummary();

            this.recentTransactions = this.currentAccount.wallet.recent_transactions;
            this.recentTransactionsDataSource = new MatTableDataSource<any>(this.recentTransactions);

            this.recentTickets = this.currentAccount.recent_tickets;

            const params = ['customer-care', 'incoming'];
            if (this.contactHistoryId) {
                params.push(this.contactHistoryId);

                if (event.source.value.text_id) {
                    params.push(event.source.value.text_id);

                    if (this.tabName) {
                        params.push(this.tabName);
                    }
                }
            }

            this.location.replaceState(params.join('/'));
            // this.router.navigate(['ahihi'], {relativeTo: this.activatedRoute});
        }
    }

    tagClick(chip: MatChip) {
        if (chip.selected) {
            chip.deselect();
        } else {
            chip.select();
        }

        this.customerCareService.toggleTag(chip.value.tag, this.contactHistoryId).subscribe((response: IResponse) => {
            if (response.success) {
                this.notifier.notify('success', 'Successfully toggle tag');
            }
        });
    }

    ngAfterViewInit(): void {

        this.listChip.chips.changes.subscribe((e: QueryList<MatChip>) => {
            if (e.last) {
                setTimeout(function () {
                    e.last.select();
                }, 100);

            }
        });
        this.changeDetectorRef.detectChanges();
    }

    sendChatMsg() {
        const text = this.msgText;
        const clientId = this.randomString(64);
        this.chatService.sendMessage(this.currentAccount.text_id, text, clientId).subscribe((res: IResponse) => {
            // this.addMessage(res.data);
        });
        this.msgText = '';
    }

    onImageUpload(e) {
        const files = e.target.files;
        for (const file of files) {
            const msg = {
                message: '',
                created_at: new Date().toISOString(),
                message_type: 'upload',
                user: {
                    is_me: true
                },
                file_data: file,
                progress: 0
            };

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => { // called once readAsDataURL is completed
                // @ts-ignore
                msg.file_data = event.target.result;
            };
            const clientId = this.randomString(64);
            this.clientIds[clientId] = 1;
            this.addMessage(msg);
            this.chatService.sendImageMessage(this.currentAccount.text_id, file, clientId).subscribe((event) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        console.log('Request has been made!');
                        break;
                    case HttpEventType.ResponseHeader:
                        console.log('Response header has been received!');
                        break;
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(event.loaded / event.total * 100);
                        msg.progress = progress;
                        console.log(`Uploaded! ${progress}%`);
                        break;
                    case HttpEventType.Response:
                        console.log('User successfully created!', event.body);
                        setTimeout(() => {
                        }, 1500);

                }
            });
        }
        e.target.value = '';
    }

    chatPressMsg(e: KeyboardEvent) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            this.sendChatMsg();
        }
    }

    close(item) {
        this.customerCareService.close(item.text_id).subscribe((res: IResponse) => {
            if (res.success) {
                this.notifier.notify('success', 'Successfully closed.');
                this.getListData();
                if (this.contactHistoryId && this.contactHistoryId === item.text_id) {
                    this.getDetail();
                    if (item.contact_channel === 'CHAT') {
                        this.sendCloseMsg();
                    }
                }
            } else {
                this.notifier.notify('error', 'Close failed. Please try again.');
            }
        });
    }

    reopen(item) {
        this.customerCareService.reopen(item.text_id).subscribe((res: IResponse) => {
            if (res.success) {
                this.notifier.notify('success', 'Successfully reopened.');
                let i = 0;
                for (; i < this.data.items.length; i++) {
                    if (this.data.items[i].item.text_id === item.text_id) {
                        break;
                    }
                }
                this.data.items[i].item.status = 'active';

            } else {
                this.notifier.notify('error', 'Reopen failed. Please try again.');
            }
        });
    }

    filterMe() {
        if (this.filter.load !== 'me') {
            this.filter.load = 'me';
            localStorage.setItem('cs_load', 'me');
            this.getListData();
        }
    }

    filterAll() {
        if (this.filter.load !== 'all') {
            this.filter.load = 'all';
            localStorage.setItem('cs_load', 'all');
            this.getListData();
        }
    }

    filterOpen() {
        if (this.filter.load !== 'open') {
            this.filter.load = 'open';
            localStorage.setItem('cs_load', 'open');
            this.getListData();
        }
    }

    changeChatConfig(e) {
        this.chatService.sendConfig(this.currentAccount.text_id, e.checked).subscribe((res: IResponse) => {
            if (res.success) {
                this.notifier.notify('success', 'Successfully set config');
            } else {
                this.notifier.notify('error', 'Failed to set config');
            }
        });
    }


    processTicket(ticket) {
        this.ticketService.patch(ticket.text_id, {status: 'doing'}).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = res.data.status;
            }
        });
    }

    pauseTicket(ticket) {
        this.ticketService.patch(ticket.text_id, {status: 'paused'}).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = res.data.status;
            }
        });
    }

    completeTicket(ticket) {
        this.ticketService.patch(ticket.text_id, {status: 'completed'}).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = res.data.status;
            }
        });
    }

    continueTicket(ticket) {
        this.ticketService.patch(ticket.text_id, {status: 'doing'}).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = res.data.status;
            }
        });
    }


    closeTicket(ticket) {
        this.ticketService.patch(ticket.text_id, {status: 'closed'}).subscribe((res: IResponse) => {
            if (res.success) {
                ticket.status = res.data.status;
            }
        });
    }


    assignToMe(contactHistoryId) {
        this.customerCareService.assignToMe(contactHistoryId).subscribe((res: IResponse) => {
            if (res.success) {
                this.notifier.notify('success', 'Successfully assigned me.');
                for (let i = 0; i < this.data.items.length; i++) {
                    if (this.data.items[i].item.text_id === contactHistoryId) {
                        this.data.items[i].item.assigned_to_me = true;
                        break;
                    }
                }
                this.getListData();
                this.getDetail();
                this.sendJoinMsg();
            } else {
                this.notifier.notify('error', 'Assigned for me failed. Please try again.');
            }
        });
    }

    sendJoinMsg() {
        this.chatService.sendJoinMessage(this.currentAccount.text_id).subscribe((res: IResponse) => {

        });
    }

    sendCloseMsg() {
        this.chatService.sendCloseMsg(this.currentAccount.text_id).subscribe((res: IResponse) => {

        });
    }

}
