import {Component, Injector, Input, OnInit} from '@angular/core';
import {CustomerCareService} from '../../customer-care.service';
import {ActivityFilter} from '../../filters';
import {IResponse} from '../../../shared/interfaces/response';
import {MatTableDataSource} from '@angular/material';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css']
})
export class ActivityComponent extends BaseListComponent implements OnInit {

    currentAccount = null;
    filter = new ActivityFilter();

    pageSize = 10;
    activities = [];
    dataSource = new MatTableDataSource(this.activities);
    currentActivity = null;

    @Input()
    public set account(value) {
        if (value !== this.currentAccount) {
            this.currentAccount = value;
            this.filter.offset = 0;
            this.filter.limit = this.pageSize;
            this.getData();
        }
    }

    constructor(
        private injector: Injector,
        private customerCareService: CustomerCareService,
    ) {
        super(injector);

        this.filterForm = this.formBuilder.group({
            search: [''],
            ref_type: [''],
            status: [''],
            result: [''],
        });
    }


    public triggerRefreshData() {
        this.getData();
    }

    ngOnInit() {
        this.selectedColumns = [
            'content',
        ];
        this.filterForm.controls.search.valueChanges.pipe(
            debounceTime(300),
        ).subscribe(value => {
            this.getData();
        });

        this.filterForm.controls.status.valueChanges.subscribe(value => this.getData());
        this.filterForm.controls.ref_type.valueChanges.subscribe(value => this.getData());
        this.filterForm.controls.result.valueChanges.subscribe(value => this.getData());
    }

    setCurrentActivity(activity) {
        this.currentActivity = activity;
    }

    protected prepareFilter() {
        const data = this.filterForm.getRawValue();
        this.filter.offset = this.pageIndex * this.pageSize;
        this.filter.limit = this.pageSize;
        this.filter.search = data.search;
        this.filter.ref_type__iexact = data.ref_type;
        this.filter.status__iexact = data.status;
        this.filter.result__iexact = data.result;
    }

    getData() {
        this.prepareFilter();
        this.customerCareService.getActivities(this.currentAccount.text_id, this.filter).subscribe((res: IResponse) => {
            this.activities = res.data.items;
            this.dataSource = new MatTableDataSource(this.activities);
            this.pageLength = res.data.pagination.total;
        });
    }

    get displayColumns() {
        return this.selectedColumns.concat(['actions']);
    }

}
