import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getMe() {
        const url = environment.api_base_url + '/me';
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.get(url, {
            headers: headers
        }).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    getProfile() {
        const url = environment.api_base_url + '/my-profile';
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.get(url, {
            headers: headers
        }).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    toggleReceiveNotification() {
        const url = environment.api_base_url + '/my-profile/toggle-receive-notification';

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post(url, {}, {headers: headers}).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }
}
