import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallHistoryListComponent} from './call-history-list/call-history-list.component';
import {
    MatCardModule, MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule, MatInputModule, MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule, MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AllMaterialModule} from '../../all-material-module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {MatSelectSearchModule} from '../../shared/mat-select-search/mat-select-search.module';
import { CallHistoryListenComponent } from './call-history-listen/call-history-listen.component';

@NgModule({
    declarations: [CallHistoryListComponent, CallHistoryListenComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        AllMaterialModule,
        SharedModule,
        RouterModule,
        MatSelectInfiniteScrollModule,
        MatSelectSearchModule
    ],
    entryComponents: [
        CallHistoryListenComponent,
    ]
})
export class CallHistoryModule {
}
