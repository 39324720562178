import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    ViewChild,
    HostListener,
    Directive,
    AfterViewInit,
    OnInit
} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {MediaMatcher} from '@angular/cdk/layout';
import {MenuItems} from '../../../shared/menu-items/menu-items';
import {environment} from '../../../../environments/environment';
import {DcmClientService} from '../../../dws/dcm/dcm-client.service';
import {MenuBadgeService} from '../../../shared/menu-badge.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {
    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;
    baseHref = environment.base_href;
    incomingBadgeNumber = 0;
    menuItems = [];
    private _menuItemDict;
    private readonly _mobileQueryListener: () => void;
    status = false;

    clickEvent() {
        this.status = !this.status;
    }

    subclickEvent() {
        this.status = true;
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuService: MenuItems,
        private menuBadge: MenuBadgeService,
    ) {

        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        if (this.mobileQuery.addEventListener) {
            this.mobileQuery.addEventListener('', this._mobileQueryListener);
        } else {
            this.mobileQuery.addListener(this._mobileQueryListener);
        }
        this.menuItems = this.menuService.getMenuItem();
    }

    ngOnDestroy(): void {
        if (this.mobileQuery.removeEventListener) {
            this.mobileQuery.removeEventListener('', this._mobileQueryListener);
        } else {
            this.mobileQuery.removeListener(this._mobileQueryListener);
        }
    }

    ngOnInit(): void {
        this.menuBadge.incomingBadgeSubject.subscribe(value => {
            this.incomingBadgeNumber = value;
            const customerCareMenu = this.findMenuItem('customer-care/incoming');
            if (customerCareMenu !== null) {
                if (value !== null && value !== 0) {
                   customerCareMenu.badge = [{type: 'warning', value: value}];
                } else {
                    customerCareMenu.badge = [];
                }
            }
        });
    }

    findMenuItem(state) {
        for (const menu of this.menuItems) {
            if (menu.state === state) {
                return menu;
            }
        }
        return null;
    }
}
