import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TicketService {
    constructor(private httpClient: HttpClient) {
    }

    patch(id, data) {
        const url = environment.api_base_url + '/ticket/' + id;
        return this.httpClient.patch(url, data).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    summary(accountTextId) {
        const url = environment.api_base_url + '/ticket/summary?account_text_id=' + accountTextId;
        return this.httpClient.get(url).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }
}
