import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsUserListComponent } from './cs-user-list/cs-user-list.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AllMaterialModule} from '../../all-material-module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import { AddCsUserDialogComponent } from './add-cs-user-dialog/add-cs-user-dialog.component';
import { SelectBackendUserComponent } from './select-backend-user/select-backend-user.component';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {MatSelectSearchModule} from '../../shared/mat-select-search/mat-select-search.module';

@NgModule({
  declarations: [CsUserListComponent, AddCsUserDialogComponent, SelectBackendUserComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AllMaterialModule,
    SharedModule,
    RouterModule,
    MatSelectInfiniteScrollModule,
    MatSelectSearchModule
  ],
  entryComponents: [
    AddCsUserDialogComponent
  ]
})
export class CsUserModule { }
