<mat-toolbar class="dv-header">
    <h3>{{prefixName + '.label_confirm_otp' | translate}}</h3>
</mat-toolbar>
<div class="dv-content">
    <div *ngIf="loading" class="dv-layout-mat-spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
    </div>
    <mat-card-content class="m-t-15" fxLayout="row wrap">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field style="width: 100%" appearance="outline" floatLabel="never">
                <input matInput type="text" [(ngModel)]="inputOtp" [placeholder]="prefixName + '.label_otp' | translate">
            </mat-form-field>
        </div>
    </mat-card-content>
    <div class="button-row" style="display: flex">
        <span style="flex: 1 1 100%"></span>
        <button mat-raised-button (click)="onNoClick()">{{prefixName + '.button_no' | translate}}</button>
        <button mat-raised-button color="primary" (click)="onYesClick()" style="min-width: auto">{{prefixName + '.button_yes' | translate}}</button>
    </div>
</div>

