export class BaseFilter {
  ordering = '-id';
  search = '';
  offset = 0;
  limit = 20;


  toFilterString(): string {
    let res = '';
    for (const key of Object.keys(this)) {
      if (this[key]) {
        res += '&' + key + '=' + this[key];
      }
    }
    return res.substr(1);
  }
}
