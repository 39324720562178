<mat-toolbar class="dv-header">
  <h3 mat-dialog-title>Create a contact history</h3>
</mat-toolbar>

<div class="dv-content">
  <mat-drawer-container class="dv-drawer-container">
    <!--    <mat-drawer #filterDrawer [mode]="mobileQuery.matches ? 'over' : 'side'" position="end" class="dv-drawer" fxLayout="column">-->
    <mat-drawer-content class="dv-drawer-content">
      <div *ngIf="loading" class="dv-layout-mat-spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
      </div>
      <form [formGroup]="form" (ngSubmit)="onFormSubmit()" class="dv-drawer-content-data">
        <div style="flex-direction: column;flex: 1 1 auto; overflow: auto">


          <mat-form-field>
            <mat-label>Contact</mat-label>
            <input matInput [formControl]="form.controls['contact']" placeholder="Contact">
            <mat-error *ngIf="form.controls.contact.touched && form.controls.contact.hasError('required')">
              Contact can not be blank
            </mat-error>
          </mat-form-field>

          <mat-form-field [formControl]="form.controls['channel']">
            <mat-label>Type</mat-label>
            <mat-select>
              <mat-option [value]="'MOBILE_PHONE'">
                Mobile Phone
              </mat-option>
              <mat-option value="HOME_PHONE">
                Home Phone
              </mat-option>
              <mat-option value="EMAIL">Email</mat-option>
              <mat-option value="ZALO">Zalo</mat-option>
              <mat-option value="FACEBOOK"> Facebook</mat-option>
              <mat-option value="OTHER">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <mat-toolbar class="dv-drawer-content-footer">
          <div mat-dialog-actions fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" align="end">
            <button mat-raised-button (click)="closeDialog()">Cancel</button>
            <button mat-raised-button color="primary" type="submit">Create</button>
          </div>
        </mat-toolbar>
      </form>
    </mat-drawer-content>
  </mat-drawer-container>

</div>
