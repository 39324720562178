import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DcmModule} from './dcm/dcm.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DcmModule,
    ]
})
export class DwsModule {
}
