<mat-toolbar class="dv-header">
    <h3>Migrate update data</h3>
    <span fxFlex="1 1 0%"></span>
    <button mat-button (click)="onRefreshClicked()">
        <mat-icon>refresh</mat-icon>
        REFRESH
    </button>
</mat-toolbar>

<div class="dv-content">
    <mat-drawer-container class="dv-drawer-container">
        <!--<mat-drawer #filterDrawer [mode]="mobileQuery.matches ? 'over' : 'side'" position="end" class="dv-drawer" fxLayout="column">-->
        <mat-drawer-content class="dv-drawer-content">
            <div *ngIf="loading" class="dv-layout-mat-spinner-container">
                <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
            </div>

            <div class="dv-drawer-content-data">
                <div class="migrate-button" class="button-row" *ngFor="let configSetting of configSettings">
                    <button mat-raised-button color="primary" (click)="onUpdateData(configSetting?.item?.key)"
                            *ngIf="configSetting?.actions?.can_update?.user_has_perm && configSetting?.actions?.can_update?.obj_has_perm">
                        {{ prefixName +'.'+ configSetting?.item?.key | translate }}
                    </button>

                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

