import {BaseFilter} from '../shared/base-filter';

export class TicketFilter extends BaseFilter {
    status__iexact = '';
}

export class TransactionFilter extends BaseFilter {
}

export class ContactHistoryFilter extends BaseFilter {
    load: string;
}

export class CsUserFilter extends BaseFilter {
}

export class BackendUserSearchFilter extends BaseFilter {
}

export class CallHistoryFilter extends BaseFilter {
}

export class ActivityFilter extends BaseFilter {
    status__iexact = '';
    ref_type__iexact = '';
    result__iexact = '';
}
