import {ChangeDetectorRef, HostBinding, Injector, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {BreakpointObserver, MediaMatcher} from '@angular/cdk/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {Observable, of, OperatorFunction, Subject} from 'rxjs';

export abstract class BaseListComponent implements OnDestroy {
  @HostBinding('class.dv-list-page')
  public layout = true;
  public loading = true;

  public filterForm: FormGroup;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public selection = new SelectionModel<Selection>(true, []);

  public mobileQuery: MediaQueryList;
  protected readonly mobileQueryListener;

  protected breakpointObserver: BreakpointObserver;
  public changeDetectorRef: ChangeDetectorRef;
  public media: MediaMatcher;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected notifier: NotifierService;
  protected formBuilder: FormBuilder;

  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 20, 50];
  pageLength = 0;

  selectedColumns = [];



  constructor(
    protected $injector: Injector
  ) {
    console.log('construct 2');
    this.formBuilder = this.$injector.get(FormBuilder);
    this.filterForm = this.formBuilder.group({
      search: [''],
      ordering: [''],
      status: [''],
    });
    this.breakpointObserver = $injector.get(BreakpointObserver);
    this.changeDetectorRef = $injector.get(ChangeDetectorRef);
    this.media = $injector.get(MediaMatcher);
    this.router = $injector.get(Router);
    this.activatedRoute = $injector.get(ActivatedRoute);
    this.notifier = $injector.get(NotifierService);

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => {
      console.log('Detect change');
      return this.changeDetectorRef.detectChanges();
    };
    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener('', this.mobileQueryListener);
    } else {
      this.mobileQuery.addListener(this.mobileQueryListener);
    }
  }

  public abstract triggerRefreshData();

  ngOnDestroy(): void {
    if (this.mobileQuery.removeEventListener) {
      this.mobileQuery.removeEventListener('', this.mobileQueryListener);
    } else {
      this.mobileQuery.removeListener(this.mobileQueryListener);
    }
  }


  onPageChange(event) {
    console.log('On Page Change');
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.triggerRefreshData();
  }

  noticeError(err) {
    this.notifier.notify('error', err.error.message);
    this.loading = false;
  }

  get displayColumns() {
    return ['select'].concat(this.selectedColumns).concat(['actions']);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public onFilterSubmit() {
    this.pageIndex = 0;
    this.triggerRefreshData();
  }

  public onFilterClear() {
    this.filterForm.reset();
  }

  public onRefreshClicked() {
    this.triggerRefreshData();
  }

  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

}
