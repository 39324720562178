<mat-list class="basic-info-tab" *ngIf="currentAccount">
  <mat-list-item><strong>Full
    Name: </strong> {{currentAccount.profile.name}}</mat-list-item>
  <mat-list-item><b>Email: </b> {{currentAccount.profile.email}}
  </mat-list-item>
  <mat-list-item><b>Phone: </b>{{currentAccount.profile.mobile_phone}}
  </mat-list-item>
  <mat-list-item><b>Identity
    Number: </b> {{currentAccount.profile.identity_id}}
  </mat-list-item>
  <mat-list-item><b>KYC Level: </b>{{currentAccount.profile.kyc_level}}</mat-list-item>
  <mat-list-item><b>Created At: </b>{{currentAccount.profile.created_at | date: 'medium'}}</mat-list-item>
</mat-list>
