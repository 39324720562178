<mat-drawer-container class="dv-drawer-container">
    <mat-drawer-content class="dv-drawer-content">

        <div class="dv-drawer-content-data">
            <div class="responsive-table">
                <mat-table [dataSource]="ticketsDataSource"
                           class="mat-elevation-z8">
                    <ng-container matColumnDef="title">
                        <mat-header-cell class="col-title" *matHeaderCellDef>Title</mat-header-cell>
                        <mat-cell class="col-title" *matCellDef="let element"> {{element.item.title}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status" >
                        <mat-header-cell *matHeaderCellDef class="col-status"> Status</mat-header-cell>
                        <mat-cell class="col-status"
                                *matCellDef="let element">
                            <span class="header-label">Status: </span>
                            <span class="label label-default" *ngIf="element.item.status == 'new'">Open</span>
                            <span class="label label-info" *ngIf="element.item.status == 'doing'">Doing</span>
                            <span class="label label-warning" *ngIf="element.item.status == 'paused'">Paused</span>
                            <span class="label label-warning" *ngIf="element.item.status == 'pending'">Pending</span>
                            <span class="label label-success" *ngIf="element.item.status == 'completed'">Completed</span>
                            <span class="label label-danger" *ngIf="element.item.status == 'cancelled'">Cancelled</span>
                            <span class="label label-success" *ngIf="element.item.status == 'closed'">Closed</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef class="button-row">
                            <button mat-icon-button color="primary" (click)="openCreateTicketDialog()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-header-cell>
                        <mat-cell class="button-row" *matCellDef="let element">
                            <button mat-raised-button [routerLink]="['/', 'ticket', element.item.text_id]">Detail</button>
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <div mat-menu-item  *ngIf="element.item.status == 'new'">
                                    <button mat-raised-button color="primary" (click)="processTicket(element.item)"> Process</button>
                                </div>
                                <div mat-menu-item *ngIf="element.item.status == 'doing'">
                                    <button mat-raised-button color="primary" (click)="completeTicket(element.item)"> Complete</button>
                                </div>
                                <div mat-menu-item   *ngIf="element.item.status == 'doing'">
                                    <button mat-raised-button color="accent"  (click)="pauseTicket(element.item)"> Pause </button>
                                </div>
                                <div mat-menu-item  *ngIf="element.item.status == 'paused'">
                                    <button mat-raised-button color="accent" (click)="continueTicket(element.item)"> Continue</button>
                                </div>

                                <button mat-menu-item (click)="editTicket(element.item)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>

                            </mat-menu>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row
                            *matHeaderRowDef="displayColumns"></mat-header-row>
                    <mat-row
                            *matRowDef="let row; columns: displayColumns;"></mat-row>
                </mat-table>
            </div>
        </div>

        <mat-toolbar class="dv-drawer-content-footer">
            <mat-paginator
                    [length]="pageLength"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="onPageChange($event)"
                    showFirstLastButtons
            ></mat-paginator>
        </mat-toolbar>
    </mat-drawer-content>

</mat-drawer-container>
