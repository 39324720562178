import { Injectable } from '@angular/core';

import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {CallHistoryFilter} from '../filters';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CallHistoryService {

  constructor(
      private httpClient: HttpClient
  ) { }

  getList(filter: CallHistoryFilter) {
    const url = environment.api_base_url + '/customer-care/call-history?' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

    listen(model) {
        const url = environment.api_base_url + '/customer-care/call-history-listen/' + model.item.id;
        return this.httpClient.get(url, {responseType: 'arraybuffer'}).pipe(
            map(results => {
                return results;
            }),
            catchError(err => {
                throw err;
            })
        );
    }
}
