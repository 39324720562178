<mat-toolbar class="dv-header">
  <h3>CS User</h3>
  <span fxFlex="1 1 0%"></span>
  <button mat-button (click)="refreshData()">
    <mat-icon>refresh</mat-icon>
    REFRESH
  </button>
</mat-toolbar>
<div class="dv-content">
  <mat-drawer-container class="dv-drawer-container">

    <mat-drawer #filterDrawer [mode]="'over'" position="end" class="dv-drawer-menu" fxLayout="column">
      <mat-card class="dv-drawer-menu-content">
        <mat-card-content>
          <form [formGroup]="filterForm" #filterFormWrapper>
            <mat-label>Sort By</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <mat-select [formControl]="filterForm.controls.ordering">
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let s of sortOptions" [value]="s.id">
                  {{s.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <hr>
            <mat-label>Search</mat-label>
            <mat-form-field appearance="outline" floatLabel="never">
              <input matInput [formControl]="filterForm.controls.search">
              <mat-hint>Search by Email, Name, Mobile Phone</mat-hint>
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-toolbar class="dv-drawer-menu-footer">
        <div class="button-row">
          <button mat-raised-button color="primary" (click)="filterDrawer.toggle(); onFilterSubmit()">Apply
          </button>
          <button mat-raised-button (click)="onFilterClear()">Clear</button>
        </div>
      </mat-toolbar>
    </mat-drawer>
    <mat-drawer-content class="dv-drawer-content">
      <div *ngIf="loading" class="dv-layout-mat-spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
      </div>
      <mat-toolbar class="dv-drawer-content-header">

        <div class="button-row">
          <button (click)="openAddCsUserDialog()" mat-raised-button color="primary">Add Cs User</button>
        </div>
        <span fxFlex="1 1 0%"></span>
        <div class="dv-column-selector">
          <mat-select [(ngModel)]="selectedColumns" [multiple]="true" style="width: 100%"
                      placeholder="Columns">
            <mat-select-trigger>Columns</mat-select-trigger>
            <mat-option *ngFor="let column of allColumns" [value]="column">{{column}}</mat-option>
          </mat-select>
        </div>
        <button mat-icon-button (click)="filterDrawer.toggle()">
          <mat-icon>filter_list</mat-icon>
        </button>
      </mat-toolbar>

      <div class="dv-drawer-content-data">
        <div class="responsive-table">
          <mat-table [dataSource]="dataSource">
            <!-- Column definition-->

            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <span class="header-label">Choose : </span>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Choose : </span>
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="text_id">
              <mat-header-cell *matHeaderCellDef>Text ID</mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.item.text_id}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone_ext">
              <mat-header-cell *matHeaderCellDef>Phone Ext</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Phone Ext : </span>{{ row.item.phone_ext }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="version">
              <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Version : </span>{{ row.item.version }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_email">
              <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Email:  </span>{{row.item.user.email}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="user_full_name">
              <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Name:  </span> {{row.item.user.full_name}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="user_phone">
              <mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="header-label">Phone: </span> {{row.item.user.phone}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let row; let i=index">
                <div class="button-row">
                  <button mat-raised-button color="warn" *ngIf="row.actions.delete.user_has_perm" (click)="removeCsUser(row.item)">Delete</button>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Row definition -->
            <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></mat-row>
          </mat-table>
        </div>
      </div>

      <mat-toolbar class="dv-drawer-content-footer">
        <mat-paginator
                [length]="pageLength"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="onPageChange($event)"
                showFirstLastButtons
        ></mat-paginator>
      </mat-toolbar>

    </mat-drawer-content>
  </mat-drawer-container>
</div>

