import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {IResponse} from '../../../shared/interfaces/response';
import {CustomerCareService} from '../../customer-care.service';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.css']
})
export class CreateContactDialogComponent implements OnInit {
  @HostBinding('class.dv-list-page') layout = true;

  loading = false;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateContactDialogComponent>,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: CustomerCareService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      contact: [this.data['contact'], Validators.required],
      channel: [null, Validators.required]
    });
    this.form.controls.channel.setValue('MOBILE_PHONE');
  }

  onFormSubmit() {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.service.createContact(data).subscribe((res: IResponse) => {
        if (res.success) {
          this.dialogRef.close(res.data);
        }
      });
    } else {

    }
  }

  closeDialog() {
    this.dialogRef.close(null);
  }


}
