import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ActivityFilter, ContactHistoryFilter, TicketFilter, TransactionFilter} from './filters';

@Injectable({
  providedIn: 'root'
})
export class CustomerCareService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getContactHistoryList(filter: ContactHistoryFilter) {
    const url = environment.api_base_url + '/customer-care/contact-history?' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getContactHistoryDetail(id, renderType = 'view') {
    const url = environment.api_base_url + '/customer-care/contact-history/' + id + '?render_type=' + renderType;
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  searchProfiles(search) {
    const url = environment.api_base_url + '/customer-care/profile-search?search=' + search;
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }


  addAccount(profileId, profileType, contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/add-account';
    return this.httpClient.post(url, {
      profile_text_id: profileId, type: profileType, contact_history_text_id: contactHistoryId
    }).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  removeAccount(accountId, contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/remove-account';
    return this.httpClient.post(url, {
      account_text_id: accountId, contact_history_text_id: contactHistoryId
    }).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  close(contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/contact-history/' + contactHistoryId + '/close';
    return this.httpClient.post(url, {}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  assignToMe(contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/contact-history/' + contactHistoryId + '/assigned';
    return this.httpClient.post(url, {}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  reopen(contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/contact-history/' + contactHistoryId + '/reopen';
    return this.httpClient.post(url, {}).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  createTicket(data) {
    const url = environment.api_base_url + '/customer-care/ticket';
    return this.httpClient.post(url, data).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  updateTicket(text_id, data) {
    const url = environment.api_base_url + '/customer-care/ticket/' + text_id;
    return this.httpClient.patch(url, data).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  toggleTag(tag, contactHistoryId) {
    const url = environment.api_base_url + '/customer-care/toggle-tag';
    return this.httpClient.post(url, {
      contact_history_text_id: contactHistoryId,
      tag: tag
    }).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  updateNote(contactHistoryId, note) {
    const url = environment.api_base_url + '/customer-care/contact-history/' + contactHistoryId;
    return this.httpClient.patch(url, {
      note: note
    }).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getTickets(accountId, filter: TicketFilter) {
    const url = environment.api_base_url + '/customer-care/ticket?account_text_id=' + accountId + '&' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getTransactions(accountId, filter: TransactionFilter) {
    const url = environment.api_base_url + '/customer-care/transaction?account_text_id=' + accountId + '&' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getContactHistoryListForAccount(accountId, filter: ContactHistoryFilter) {
    const url = environment.api_base_url +
        '/customer-care/account-contact-history?account_text_id='
        + accountId + '&' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  createContact(data) {
    const url = environment.api_base_url + '/customer-care/contact-history';
    return this.httpClient.post(url, data).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  markDoneTicket(text_id) {
    const url = environment.api_base_url + '/customer-care/ticket/' + text_id;
    return this.httpClient.patch(url, {
      status: 'done'
    }).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  deleteTicket(text_id) {
    const url = environment.api_base_url + '/customer-care/ticket/' + text_id;
    return this.httpClient.delete(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getAllUsers() {
    const url = environment.api_base_url +
        '/customer-care/ticket/all-user';
    return this.httpClient.get(url).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  getMe() {
    const url = environment.api_base_url +
        '/customer-care/ticket/me';
    return this.httpClient.get(url).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  getAllTicketCategory() {
    const url = environment.api_base_url +
        '/ticket/category';
    return this.httpClient.get(url).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

  recentTickets(accountTextId) {
    const url = environment.api_base_url + '/customer-care/ticket/recent?account_text_id=' + accountTextId;
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  getActivities(accountTextId, filter: ActivityFilter) {
    let url = environment.api_base_url + '/customer-care/activity?account_text_id=' + accountTextId;
    if (filter != null) {
      url += '&' + filter.toFilterString();
    }
    return this.httpClient.get(url).pipe(
        map(results => {
          return results;
        }),
        catchError(err => {
          throw err;
        })
    );
  }

}
