<div class="group-container">
    <div class="avatar-container">
        <div class="chat-avatar" [ngStyle]="{background: 'url( ' + group.avatar + ' )'}"></div>
    </div>
    <div class="group-content">
        <div *ngFor="let item of group.messages; let i=index;" class="message-container" [ngClass]="'status-' + item.status">
            <div class="message">


                <div *ngIf="i == 0" class="full-name">
                   {{item.user.full_name}}
                </div>
                <div *ngIf="item.message_type == 'image'">
                    <a [href]="item.file" target="_blank"><img [src]="item.file" style="max-width: 100%"></a>
                </div>
                <div><p style='word-break: break-all; margin: 5px'>{{item.message}}</p></div>
                <div class="message-time">{{item.created_at | date: 'shortTime'}}</div>
            </div>
        </div>
    </div>
</div>
