import {Component, OnInit} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthService} from '../../../authentication/auth.service';
import {Router} from '@angular/router';
import {HeaderService} from './header.service';
import {IResponse} from '../../../shared/interfaces/response';
import {MultiLanguageService} from '../../../shared/translate/multiLanguageService';
import {NotifierService} from 'angular-notifier';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
    name = '';
    prefixName = 'layout_full';
    myProfile;

    public constructor(
        private router: Router,
        private service: AuthService,
        public headerService: HeaderService,
        public multiLanguageService: MultiLanguageService,
        private notifier: NotifierService,
    ) {
        this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
    }

    public config: PerfectScrollbarConfigInterface = {};
    ngOnInit() {
        this.headerService.getProfile().subscribe((res: IResponse) => {
            this.myProfile = res.data;
        });
    }

    onSignOutClicked() {
        this.service.logout().subscribe(res => {
            if (res.success) {
                this.router.navigate(['/authentication/login']);
            }
        });
    }

    onChangeLanguage(language) {
        this.multiLanguageService.changeLanguage(language);
    }

    toggleReceiveNotification() {
        this.headerService.toggleReceiveNotification().subscribe(res => {
            if (res.success) {
                this.myProfile.is_receive_notification = res.data.is_receive_notification;
                this.notifier.notify('success', 'Successfully changed');
            } else {
                this.notifier.notify('warning', 'Something went wrong!. Please check again');
            }
        }, err => {
            this.notifier.notify('error', 'Failed changed. Please try again');
        });
    }
}
