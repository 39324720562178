import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    registerDcmToken(data) {
        const url = environment.api_base_url + '/tools/messaging/register';

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.post(url, data, {
            headers: headers,
            // withCredentials: true,
        }).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                throw err;
            })
        );
    }
}
