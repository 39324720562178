import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {BackendUserSearchFilter, CsUserFilter} from '../filters';

@Injectable({
  providedIn: 'root'
})
export class CsUserService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getCsUserList(filter: CsUserFilter) {
    const url = environment.api_base_url + '/customer-care/cs-user?' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  createCsUser(data) {
    const url = environment.api_base_url + '/customer-care/cs-user';
    return this.httpClient.post(url, data).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  searchBackendUser(filter: BackendUserSearchFilter) {

    const url = environment.api_base_url + '/customer-care/backend-user?' + filter.toFilterString();
    return this.httpClient.get(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }

  removeCsUser(obj) {
    const url = environment.api_base_url + '/customer-care/cs-user/' + obj.text_id;
    return this.httpClient.delete(url).pipe(
      map(results => {
        return results;
      }),
      catchError(err => {
        throw err;
      })
    );
  }
}
