import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MenuBadgeService {
    incomingBadgeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private _incomingBadgeNumber = 0;
    constructor() {
    }

    addIncomingBadge(number: number) {
        if (number === null) {
            this._incomingBadgeNumber = 0;
        } else {
            this._incomingBadgeNumber += number;
        }
        this.incomingBadgeSubject.next(this._incomingBadgeNumber);
    }
}
