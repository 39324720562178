import { Injectable} from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from './auth.service';


const ERROR_CODE__CHANGE_PASSWORD_REQUIRED = "-03"

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private auth: AuthService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('START AUTH INTERCEPTOR');
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request)
            .pipe(
                tap(event => {
                    // console.log(event);
                }, error => {
                    if (error instanceof HttpErrorResponse) {
                        return this.handleAuthError(error);
                    }
                })
            );
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        switch (err.status) {
            case 401:
                let errorCode = null;
                if (err.error && err.error.code) {
                    errorCode = err.error.code;
                }

                switch (errorCode) {
                    case ERROR_CODE__CHANGE_PASSWORD_REQUIRED:
                        return of(this.router.navigate(['authentication/change-password'], {state: {data: {isMustChangePassword: true}}}))
                    default:
                        return of(this.router.navigate(['authentication/login']));
                }
            default:
                throwError(err);
        }
    }
}

export let authInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
