

<div class="group-container">

  <div class="group-content">
    <div *ngFor="let item of group.messages; let i=index;" class="message-container" [ngClass]="'status-' + item.status">
      <button *ngIf="item.status!='deleted'" class="action-btn" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="delete(item)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>

      <div class="message">


        <div *ngIf="i == 0" class="full-name">
          {{item.user.display_name ? item.user.display_name : item.user.full_name }}
        </div>
        <div *ngIf="item.message_type == 'image'">
          <a [href]="item.file" target="_blank"><img [src]="item.file" style="max-width: 100%"></a>
        </div>
        <div *ngIf="item.message_type == 'upload'">
          <img [src]="item.file_data" style="max-width: 100%">
          <mat-progress-bar *ngIf="item.progress < 100" mode="determinate" [value]="item.progress"></mat-progress-bar>
        </div>
        <div><p style='margin: 5px; word-break: break-all' [matTooltip]="item.status == 'deleted' ? 'This message has been deleted by ' + item.deleted_by_obj?.name + ' at ' + item.deleted_at: ''">{{item.message}}</p></div>
        <div class="message-time" [matTooltip]="item.created_at">{{item.created_at | date: 'shortTime'}}</div>

      </div>
    </div>
  </div>
  <div class="avatar-container">
    <div class="chat-avatar" [ngStyle]="{background: 'url( ' + group.avatar  + ' )'}"></div>
  </div>
</div>
