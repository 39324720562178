import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {of, Subject} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {IResponse, IResponseItems} from '../../../shared/interfaces/response';
import {HttpErrorResponse} from '@angular/common/http';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {CsUserService} from '../cs-user.service';
import {CsUserFilter} from '../../filters';
import {FormBuilder} from '@angular/forms';
import {AddCsUserDialogComponent} from '../add-cs-user-dialog/add-cs-user-dialog.component';

@Component({
  selector: 'app-cs-user-list',
  templateUrl: './cs-user-list.component.html',
  styleUrls: ['./cs-user-list.component.css']
})
export class CsUserListComponent extends BaseListComponent implements OnInit {

  allColumns = [
    'text_id',
    'user_email',
    'user_full_name',
    'user_phone',
    'phone_ext',
    'version',

  ];

  sortOptions = [
    {
      id: 'id',
      text: 'ID ASC'
    },
    {
      id: '-id',
      text: 'ID DESC'
    }
  ];


  filter = new CsUserFilter();


  private _refreshPageDataTrigger: Subject<any>;

  constructor(
    protected injector: Injector,
    protected csUserService: CsUserService,
    protected addCsUserDialog: MatDialog
  ) {
    super(injector);

  }

  protected prepareFilter() {
    const data = this.filterForm.getRawValue();
    this.filter.offset = this.pageIndex * this.pageSize;
    this.filter.limit = this.pageSize;
    this.filter.search = data.search;
    this.filter.ordering = data.ordering;
  }

  protected registerEvents() {
    if (this._refreshPageDataTrigger == null || this._refreshPageDataTrigger.isStopped) {
      this._refreshPageDataTrigger = new Subject<any>();
    }
    this._refreshPageDataTrigger.pipe(
      flatMap(() => {
        this.loading = true;
        this.prepareFilter();
        return this.csUserService.getCsUserList(this.filter);
      }),
      flatMap((res: IResponse) => {
        console.log(res);
        if (!res.success) {
          const messages = res.messages ? res.messages.error[0] : 'Server Error, please contact your admin or retry later';
          this.notifier.notify('error', messages);
          this.loading = false;
          return of(null);
        }
        this.parseData(res.data);
        return of(null);
      })
    ).subscribe(
      () => {
        this.loading = false;
      },
      (error) => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          console.log(error);
          this.notifier.notify('error', 'Server Error, please contact your admin or retry later');
          this.loading = false;
          return;
        }
        if (error instanceof HttpErrorResponse && error.status === 403) {
          console.log(error);
          this.loading = false;
          return this.router.navigate(['/authentication/403'], {replaceUrl: true});
        }
        const _msg = error.message ? error.message : 'Get Data failed';
        this.notifier.notify('error', _msg);
        this.loading = false;
      },
      () => {
      }
    );
  }

  ngOnInit() {

    this.selectedColumns = [
      'user_email',
      'user_full_name',
      'user_phone',
      'phone_ext',
    ];
    this.registerEvents();

    this.triggerRefreshData();

  }

  refreshData() {
    this.triggerRefreshData();
  }

  protected parseData(data: IResponseItems) {
    this.pageLength = data.pagination.total;
    this.dataSource = new MatTableDataSource(data.items);
  }

  triggerRefreshData(): void {
    this._refreshPageDataTrigger.next();
  }

  openAddCsUserDialog() {
    const dialogRef = this.addCsUserDialog.open(AddCsUserDialogComponent, {
      panelClass: 'dv-flex-dialog',
      minWidth: '500px',
      width: '50%',
      minHeight: '400px',
      height: '50%',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.refreshData();
      }
    });
  }

  removeCsUser(obj) {
    this.csUserService.removeCsUser(obj).subscribe((res: IResponse) => {
      this.notifier.notify('success', 'Successfully deleted!');
      this.refreshData();
    }, error => {
      if (error instanceof HttpErrorResponse && error.status === 500) {
        console.log(error);
        this.notifier.notify('error', 'Server Error, please contact your admin or retry later');
        this.loading = false;

      }
      if (error instanceof HttpErrorResponse && error.status === 403) {
        console.log(error);
        this.loading = false;
        return this.router.navigate(['/authentication/403'], {replaceUrl: true});
      }
      const _msg = error.message ? error.message : 'Get Data failed';
      this.notifier.notify('error', _msg);
      this.loading = false;
    });
  }

}
