<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-10">-->
    <!--<mat-icon>message</mat-icon>-->
    <!--<div class="notify">-->
        <!--<span class="heartbit"></span>-->
        <!--<span class="point"></span>-->
    <!--</div>-->
<!--</button>-->
<!--<mat-menu #notification="matMenu" class="mymegamenu">-->
    <!--<div class="mailbox">-->
        <!--<ul>-->
            <!--<li>-->
                <!--<div class="drop-title">Notifications</div>-->
            <!--</li>-->
            <!--<li>-->
                <!--<div class="message-center">-->
                    <!--<perfect-scrollbar [config]="config">-->
                        <!--&lt;!&ndash; Message &ndash;&gt;-->
                        <!--<a href="#" *ngFor="let notification of notifications">-->
                            <!--<div class="round {{notification.round}}">-->
                                <!--<i class="{{notification.icon}}"></i>-->
                            <!--</div>-->
                            <!--<div class="mail-content">-->
                                <!--<h5>{{notification.title}}</h5>-->
                                <!--<span class="mail-desc">{{notification.subject}}</span>-->
                                <!--<span class="time">{{notification.time}}</span>-->
                            <!--</div>-->
                        <!--</a>-->

                    <!--</perfect-scrollbar>-->
                <!--</div>-->
            <!--</li>-->

        <!--</ul>-->
    <!--</div>-->
<!--</mat-menu>-->
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button [matMenuTriggerFor]="message" mat-icon-button class="m-r-10">-->
    <!--<mat-icon>email</mat-icon>-->
    <!--<div class="notify">-->
        <!--<span class="heartbit"></span>-->
        <!--<span class="point"></span>-->
    <!--</div>-->
<!--</button>-->
<!--<mat-menu #message="matMenu" class="mymessage">-->
    <!--<div class="mailbox">-->
        <!--<ul>-->
            <!--<li>-->
                <!--<div class="drop-title">You have 4 new Mymessages</div>-->
            <!--</li>-->
            <!--<li>-->
                <!--<div class="message-center">-->
                    <!--<perfect-scrollbar [config]="config">-->
                        <!--&lt;!&ndash; Message &ndash;&gt;-->
                        <!--<a href="#" *ngFor="let mymessage of mymessages">-->
                            <!--<div class="user-img">-->
                                <!--<img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">-->
                                <!--<span class="profile-status {{mymessage.status}} pull-right"></span>-->
                            <!--</div>-->
                            <!--<div class="mail-content">-->
                                <!--<h5>{{mymessage.from}}</h5>-->
                                <!--<span class="mail-desc">{{mymessage.subject}}</span>-->
                                <!--<span class="time">{{mymessage.time}}</span>-->
                            <!--</div>-->
                        <!--</a>-->
                        <!--&lt;!&ndash; Message &ndash;&gt;-->
                    <!--</perfect-scrollbar>-->
                <!--</div>-->
            <!--</li>-->
        <!--</ul>-->
    <!--</div>-->
<!--</mat-menu>-->
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-nav-list class="list-horizontal">
    <mat-list-item (click)="onChangeLanguage('en')">
        <span *ngIf="multiLanguageService.currentLanguage() == 'en'; else otherEN" style="text-decoration: underline;">EN</span>
        <ng-template #otherEN>
            EN
        </ng-template>
    </mat-list-item>
    |
    <mat-list-item (click)="onChangeLanguage('vn')">
        <span *ngIf="multiLanguageService.currentLanguage() == 'vn'; else otherVN" style="text-decoration: underline;">VI</span>
        <ng-template #otherVN>
            VI
        </ng-template>
    </mat-list-item>

    <mat-list-item routerLink="/profile" fxShow.lt-md="false">
        {{myProfile?.email}}
    </mat-list-item>

    <mat-list-item>
        <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
            <img src="assets/images/users/default-avatar.png" alt="user" class="profile-pic" *ngIf="!myProfile?.avatar">
            <img [src]="'' + myProfile?.avatar + ''" alt="user" class="profile-pic" *ngIf="myProfile?.avatar">
        </button>
        <mat-menu #profile="matMenu" class="mymegamenu">
            <button mat-menu-item (click)="$event.stopPropagation()">
                <mat-slide-toggle [checked]="myProfile?.is_receive_notification" (change)="toggleReceiveNotification()">
                    Receive notification
                </mat-slide-toggle>
            </button>
            <button mat-menu-item routerLink="/profile">
                <mat-icon>account_box</mat-icon> Profile </button>
            <button mat-menu-item [routerLink]="['/authentication/change-password']">
                <mat-icon>vpn_key</mat-icon> {{ prefixName + '.change_password' | translate }} </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="onSignOutClicked()">
                <mat-icon>exit_to_app</mat-icon> {{ prefixName + '.sign_out' | translate }} </button>
        </mat-menu>
    </mat-list-item>
</mat-nav-list>
