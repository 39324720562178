<mat-toolbar class="dv-header">
    <h3>{{title}}</h3>
    <span fxFlex="1 1 0%"></span>
    <button mat-button (click)="onRefreshClicked()">
        <mat-icon>refresh</mat-icon>
        REFRESH
    </button>
</mat-toolbar>

<div class="dv-content">
    <mat-drawer-container class="dv-drawer-container">
        <mat-drawer #filterDrawer [mode]="'over'" position="end" class="dv-drawer-menu" fxLayout="column">
            <mat-card class="dv-drawer-menu-content">
                <mat-card-content>
                </mat-card-content>
            </mat-card>
            <mat-toolbar class="dv-drawer-menu-footer">
                <div class="button-row">
                </div>
            </mat-toolbar>
        </mat-drawer>

        <mat-drawer-content class="dv-drawer-content">
            <div *ngIf="loading" class="dv-layout-mat-spinner-container">
                <mat-spinner [diameter]="30" [strokeWidth]="3" class="dv-layout-mat-spinner"></mat-spinner>
            </div>

            <div class="dv-drawer-content-data">
                <mat-card class="dv-mat-card">
                    <mat-card-title style="display: flex">
                        <strong><h3><b>{{ prefixName + '.information' | translate }}</b></h3></strong>
                    </mat-card-title>
                    <mat-card-content>

                        <div class="avatar-container">
                            <div *ngIf="!detailData?.avatar" class="avatar" [ngStyle]="{'background-image': 'url(assets/images/users/default-avatar.png)'}">

                            </div>

                            <div *ngIf="detailData?.avatar" class="avatar" [ngStyle]="{'background-image': 'url(' +detailData?.avatar+ ')'}">
                            </div>

                            <div class="action">
                                <button color="primary" onclick="image_file.click()" mat-icon-button>
                                    <mat-icon>camera_alt</mat-icon>
                                    <input type="file" (change)="onImageUpload($event)" id="image_file"  accept=".png, .jpg, .jpeg"
                                           style="display: none">
                                </button>

                            </div>
                            <mat-progress-bar [value]="avatarUploadProgress" *ngIf="avatarUploadProgress > 0"></mat-progress-bar>
                        </div>


                        <mat-list>
                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <div style="flex: 40 0 0"><b>{{ prefixName + '.full_name' | translate }}</b></div>
                                <div style="flex: 60 0 0; display: flex">
                                    <div *ngIf="!isEditingName" style="align-self: center">
                                        <span>{{detailData?.first_name}} {{detailData?.last_name}}</span>
                                    </div>
                                    <div *ngIf="isEditingName" style="align-self: center">
                                        <div style="width: 70%">
                                            <mat-form-field class="dv-center-form-field" style="width:40%;" appearance="outline" floatLabel="never">
                                                <input matInput [(ngModel)]="inputFirstName" placeholder="First name" (ngModelChange)="onNameChanged()">
                                            </mat-form-field>
                                            <mat-form-field class="dv-center-form-field" style="width: 60%;" appearance="outline" floatLabel="never">
                                                <input matInput [(ngModel)]="inputLastName" placeholder="Last name" (ngModelChange)="onNameChanged()">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="flex: 1 0 0"></div>
                                    <div *ngIf="!isEditingName" class="button-row">
                                        <button mat-raised-button (click)="beginEditingName()">
                                            {{ 'edit' | translate }}
                                        </button>
                                    </div>
                                    <div *ngIf="isEditingName" class="button-row" style="align-self: center">
                                        <button mat-raised-button color="primary" (click)="onSaveEditDetail()" [disabled]="isSaveButtonDisabled">{{ 'save' | translate }}</button>
                                        <button mat-raised-button (click)="onCloseEditDetail()">{{ 'discard' | translate }}</button>
                                    </div>
                                </div>
                            </mat-list-item>

                            <ng-container *ngFor="let obj of data">
                                <mat-divider></mat-divider>
                                <mat-list-item>
                                    <div style="flex: 40 0 0"><b>{{ prefixName + '.' + obj.name | translate }}</b></div>
                                    <div style="flex: 60 0 0; display: flex">
                                        <div *ngIf="!obj.isEditing" style="align-self: center">
                                            {{obj.value}}
                                            <span class="not_verified" *ngIf="obj.verification_status == 'NOT_VERIFIED'">
                                                ({{ prefixName + '.not_verified' | translate }})
                                            </span>
                                        </div>
                                        <div *ngIf="obj.isEditing" style="align-self: center">
                                            <mat-form-field *ngIf="obj.type == 'text'" class="dv-center-form-field" appearance="outline" floatLabel="never">
                                                <input matInput [(ngModel)]="obj.input" (ngModelChange)="onChanged(obj)">
                                            </mat-form-field>
                                            <mat-form-field *ngIf="obj.type == 'phone'" class="dv-center-form-field" style="width: 100%" appearance="outline" floatLabel="never">
                                                <ngx-mat-intl-tel-input
                                                        [preferredCountries]="['vn']"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        placeholder="Mobile phone"
                                                        [(ngModel)]="obj.input">
                                                </ngx-mat-intl-tel-input>
                                            </mat-form-field>
                                        </div>
                                        <div style="flex: 1 0 0"></div>
                                        <div *ngIf="!obj.isEditing" class="button-row">
                                            <button mat-raised-button (click)="beginEditing(obj)">
                                                {{ 'Edit' | translate }}
                                            </button>
                                        </div>
                                        <div *ngIf="obj.isEditing" class="button-row" style="align-self: center">
                                            <button mat-raised-button color="primary" (click)="onSaveEdit(obj)" [disabled]="obj.isSaveButtonDisabled">{{ 'save' | translate }}</button>
                                            <button mat-raised-button (click)="onCloseEditDetail()">{{ 'discard' | translate }}</button>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>

                            <mat-divider></mat-divider>
                            <mat-list-item>
                                <div fxFlex="40"><b>{{ prefixName + '.active' | translate }}</b></div>
                                <div fxFlex="60">
                                    {{(detailData?.is_active ? prefixName + '.actived': prefixName + '.not_actived') | translate}}
                                </div>
                            </mat-list-item>

                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</div>
