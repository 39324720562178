import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {IResponse} from '../../../shared/interfaces/response';
import {CsUserService} from '../cs-user.service';
import {BackendUserSearchFilter} from '../../filters';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-add-cs-user-dialog',
  templateUrl: './add-cs-user-dialog.component.html',
  styleUrls: ['./add-cs-user-dialog.component.css']
})
export class AddCsUserDialogComponent implements OnInit {
  @HostBinding('class.dv-list-page') layout = true;

  loading = false;
  form: FormGroup;

  searchFilter = new FormControl();
  totalCount = 0;
  backendUserData = [];

  filter: BackendUserSearchFilter;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCsUserDialogComponent>,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private service: CsUserService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      phone_ext: [null, Validators.required],
      user_text_id: [null, Validators.required]
    });

    this.filter = new BackendUserSearchFilter();
    this.searchFilter.valueChanges.pipe(
      debounceTime(300))
      .subscribe(value => {
        this.onSearchValueChange(value);
      });
  }

  onSearchValueChange(value) {
    this.data = [];
    this.totalCount = null;
    this.getData();
  }



  onFormSubmit() {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      this.service.createCsUser(data).subscribe((res: IResponse) => {
        if (res.success) {
          this.dialogRef.close(res.data);
        }
      });
    } else {

    }
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  getNextBatch() {
    if (!this.loading) {
      this.getData();
    }
  }

  isScrollComplete() {
    return (!this.loading && this.totalCount !== null && this.backendUserData.length >= this.totalCount);
  }

  getData() {
    this.loading = true;
    this.filter.offset = this.backendUserData.length;
    this.filter.search = this.searchFilter.value;
    this.service.searchBackendUser(this.filter)
      .subscribe((res: IResponse) => {
        this.backendUserData = this.backendUserData.concat(res.data.items);
        this.totalCount = res.data.pagination.total;
        this.loading = false;
      });
  }

}
