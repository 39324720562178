import {Component, Injector, OnInit} from '@angular/core';
import {BaseListComponent} from '../../../shared/components/base-list.component';
import {CallHistoryService} from '../call-history.service';
import {CallHistoryFilter} from '../../filters';
import {IResponse, IResponseItems} from '../../../shared/interfaces/response';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {CallHistoryListenComponent} from '../call-history-listen/call-history-listen.component';

@Component({
    selector: 'app-call-history-list',
    templateUrl: './call-history-list.component.html',
    styleUrls: ['./call-history-list.component.css']
})
export class CallHistoryListComponent extends BaseListComponent implements OnInit {

    filter = new CallHistoryFilter();
    allColumns = [
        'accountcode',
        'amaflags',
        'billsec',
        'calldate',
        'channel',
        'clid',
        'cnam',
        'cnum',
        'dcontext',
        'did',
        'disposition',
        'dst',
        'dst_cnam',
        'dstchannel',
        'duration',
        'lastapp',
        'lastdata',
        'outbound_cnam',
        'outbound_cnum',
        'recordingfile',
        'src',
        'uniqueid',
        'userfield',
        'created_at',
        'updated_at',

    ];

    sortOptions = [
        {
            id: 'id',
            text: 'ID ASC'
        },
        {
            id: '-id',
            text: 'ID DESC'
        }
    ];

    constructor(
        protected injector: Injector,
        private service: CallHistoryService,
        private dialog: MatDialog
    ) {
        super(injector);
    }

    ngOnInit() {
        this.selectedColumns = [
            'calldate',
            'duration',
            'cnam',
            'cnum',
            'src',
            'dst',
            'uniqueid'
        ];

        this.triggerRefreshData();
    }
    protected parseData(data: IResponseItems) {
        this.pageLength = data.pagination.total;
        this.dataSource = new MatTableDataSource(data.items);
    }

    triggerRefreshData() {
        this.service.getList(this.filter).subscribe((res: IResponse) => {
            this.parseData(res.data);
            this.loading = false;
        });
    }

    openPlayRecordDialog(item) {
        const dialogRef = this.dialog.open(CallHistoryListenComponent, {
            width: '500px',
            data: {
                model: item,
            }
        });
    }

}
