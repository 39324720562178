import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource, PageEvent} from '@angular/material';
import {CustomerCareService} from '../../customer-care.service';
import {IResponse} from '../../../shared/interfaces/response';
import {TransactionFilter} from '../../filters';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  currentAccount = null;
  filter = new TransactionFilter();
  transactions = [];
  transactionsDataSource = new MatTableDataSource(this.transactions);

  displayColumns = [
    'created_at',
    'ref_type',
    'amount',
    'after_balance'
  ];
  pageLength = 0;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 50];

  ngOnInit(): void {
  }


  @Input()
  public set account(value) {
    if (value !== this.currentAccount) {
      this.currentAccount = value;
      this.filter.offset = 0;
      this.filter.limit = this.pageSize;
      this.getData();
    }
  }

  constructor(
    private customerCareService: CustomerCareService
  ) {
  }

  getData() {
    this.customerCareService.getTransactions(this.currentAccount.text_id, this.filter).subscribe((response: IResponse) => {
      if (response.success) {
        this.transactions = response.data.items;
        this.transactionsDataSource = new MatTableDataSource(this.transactions);
        this.pageLength = response.data.pagination.total;
      }
    });
  }

  public onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.filter.offset = this.pageIndex * this.pageSize;
    this.filter.limit = this.pageSize;
    this.getData();
  }

}
