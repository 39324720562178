
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Listen call recording
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <audio controls #audio>

      Your browser does not support to play audio.
    </audio>
  </mat-card-content>
</mat-card>
