<div class="change-password">
    <div class="change-password-box">
        <mat-card>
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="onChange()">
                    <div class="text-center">
                        <!--<img alt="homepage" src="assets/images/logo-icon.png">-->
                        <h4 class="m-t-0">Change password</h4>
                        <h5 *ngIf="isMustChangePassword">Vui lòng đổi mật khẩu để tiếp tục sử dụng dịch vụ</h5>
                    </div>

                    <div fxLayout="row wrap">
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-label>Current password</mat-label>
                            <mat-form-field style="width: 100%" appearance="outline" floatLabel="never">
                                <input matInput type="password" placeholder="Current password" [formControl]="form.controls['old_password']">
                            </mat-form-field>
                            <small class="text-danger support-text">
                                       <span *ngIf="
                                            form.controls['old_password'].hasError('required')
                                            && form.controls['old_password'].touched
                                            && !form.controls['old_password'].hasError('ajaxValidator')">Password is required.</span>
                                <span *ngIf="
                                            form.controls['old_password'].hasError('ajaxValidator')
                                            && form.controls['old_password'].touched">{{ form.controls['old_password'].getError('ajaxValidator')?.value}}</span>
                            </small>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-label>New password</mat-label>
                            <mat-form-field style="width: 100%" appearance="outline" floatLabel="never">
                                <input matInput type="password" placeholder="New password" [formControl]="form.controls['new_password']">
                            </mat-form-field>
                            <small class="text-danger support-text">
                                <span *ngIf="
                                            form.controls['new_password'].hasError('required')
                                            && form.controls['new_password'].touched
                                            && !form.controls['new_password'].hasError('ajaxValidator')">New password is required.</span>
                                <span *ngIf="
                                            form.controls['new_password'].hasError('ajaxValidator')
                                            && form.controls['new_password'].touched">{{ form.controls['new_password'].getError('ajaxValidator')?.value}}</span>
                            </small>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-label>Confirm new password</mat-label>
                            <mat-form-field style="width: 100%" appearance="outline" floatLabel="never">
                                <input matInput type="password" placeholder="Confirm new password" [formControl]="form.controls['new_password_confirm']">
                            </mat-form-field>
                            <small class="text-danger support-text">
                                <span *ngIf="
                                            form.controls['new_password_confirm'].hasError('required')
                                            && form.controls['new_password_confirm'].touched
                                            && !form.controls['new_password_confirm'].hasError('ajaxValidator')">Confirmation password is required.</span>
                                <span *ngIf="
                                            form.controls['new_password_confirm'].hasError('ajaxValidator')
                                            && form.controls['new_password_confirm'].touched">{{ form.controls['new_password_confirm'].getError('ajaxValidator')?.value}}</span>
                                <span *ngIf="form.hasError('passwordNotMatch')
                                            && !form.controls['new_password_confirm'].hasError('ajaxValidator')
                                            && form.controls['new_password'].touched
                                            && form.controls['new_password_confirm'].touched">Passwords don't match</span>
                            </small>
                        </div>

                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <small class="text-danger support-text"
                                   *ngIf="form.hasError('ajaxValidator')
                                        && form.touched">{{ form.getError('ajaxValidator')?.value}}
                            </small>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit" [disabled]="!form.valid">Change</button>
                        </div>

                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>