import {Component, Input, OnInit} from '@angular/core';
import {MessageGroup} from '../models';
import {ChatService} from '../../chat-service';
import {IResponse} from '../../../shared/interfaces/response';

@Component({
    selector: 'app-sent-msg',
    templateUrl: './sent-msg.component.html',
    styleUrls: ['./sent-msg.component.css']
})
export class SentMsgComponent implements OnInit {

    @Input() group: MessageGroup;

    constructor(
        private chatMsg: ChatService
    ) {
    }

    ngOnInit() {
    }

    delete(msg) {
        this.chatMsg.deleteMessage(msg).subscribe((res: IResponse) => {
            if (res.success) {
                msg.status = res.data.status;
                msg.deleted_by = res.data.deleted_by;
                msg.deleted_at = res.data.deleted_at;
                msg.deleted_by_obj = res.data.deleted_by_obj;
            }
        });
    }

}
