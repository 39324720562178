import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DcmClientService, connectionStatus} from './dws/dcm/dcm-client.service';
import {environment} from '../environments/environment';
import {operations} from './dws/dcm/dcm-client.service';
import {AppService} from './app.service';
import {Subscription} from 'rxjs';
import {FireBaseMessagingService} from './shared/firebase-messaging.service';
import {MenuBadgeService} from './shared/menu-badge.service';
import {Router} from '@angular/router';

const dcmConfig = environment.dwsConfig.dcmConfig;
const dcmConnectionString = dcmConfig.connectionString;
const dcmAppId = dcmConfig.appId;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    private tokenReceivedSubscription: Subscription;
    private getTokenInterval;
    private registerTokenInterval;

    public constructor(
        private titleService: Title,
        private appService: AppService,
        private dcmService: DcmClientService,
        private firebaseMessagingService: FireBaseMessagingService,
        private menuBadgeService: MenuBadgeService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.titleService.setTitle('Operator Center');

        this.dcmService.onConnectionStatusChangedSubject.subscribe(value => {
            switch (value.status) {
                case connectionStatus.connected:
                    // register event
                    this.dcmService.registerToken();
                    this.getTokenInterval = setInterval(() => {
                        this.dcmService.getToken();
                    }, environment.messagingConfig.getTokenIntervalInSeconds * 1000);
                    this.tokenReceivedSubscription = this.dcmService.onTokenReceivedSubject.subscribe(
                        (data) => {
                            this.appService.registerDcmToken(data).subscribe();
                        },
                        (error) => {
                        },
                        () => {
                        }
                    );
                    break;
                case connectionStatus.closed:
                    if (this.tokenReceivedSubscription !== null && this.tokenReceivedSubscription !== undefined) {
                        this.tokenReceivedSubscription.unsubscribe();
                    }
                    if (this.getTokenInterval !== null && this.getTokenInterval !== undefined) {
                        clearInterval(this.getTokenInterval);
                    }
                    break;
            }
        });

        this.registerTokenInterval = setInterval(() => {
            if (this.dcmService.tokenData !== null && this.dcmService.tokenData !== undefined) {
                const data = this.dcmService.tokenData;
                this.appService.registerDcmToken(data).subscribe();
            }
        }, environment.messagingConfig.registerTokenIntervalInSeconds * 1000);

        this.dcmService.onMessageReceivedSubject.subscribe((value => {
            console.log('app component on message');
            console.log(value);
            value = JSON.parse(value);
            if (value.event === 'chat.message') {
                if (this.router.url.indexOf('customer-care/incoming') === -1) {
                    this.menuBadgeService.addIncomingBadge(1);
                }
            }
        }));

        this.firebaseMessagingService.registerToken();
    }

    ngOnDestroy() {
        clearInterval(this.registerTokenInterval);
    }
}
