import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {Observable, BehaviorSubject} from 'rxjs-compat';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FireBaseMessagingService {
    currentMessage = new BehaviorSubject(null);

    constructor(
        private messaging: AngularFireMessaging,
        private httpClient: HttpClient,
    ) {
        this.messaging.messages.subscribe((message: any) => {
            message.onMessage = message.onMessage.bind(message);
            message.onTokenRefresh = message.onTokenRefresh.bind(message);
        });
    }

    getToken() {
        return this.messaging.getToken;
    }

    requestToken() {
        return this.messaging.requestToken;
    }

    requestPermission() {
        return this.messaging.requestPermission;
    }

    registerToken() {
        if (Notification.permission === 'granted') {
            this.requestToken().subscribe(token => {
                console.log('TOKEN: ');
                console.log(token);
                this.sendRegisterToken(token);
            }, err => {
                console.log(err);
            });
        }
    }

    sendRegisterToken(token: string) {
        this.httpClient.post(environment.api_base_url + '/tools/messaging/register-web', {
            token
        }).subscribe();
    }

    // requestPermission() {
    //     this.messaging.requestToken.subscribe(
    //         (token) => {
    //             console.log(token);
    //         },
    //         (err) => {
    //             console.error('Unable to get permission to notify.', err);
    //         }
    //     );
    // }

    receiveMessage() {
        this.messaging.messages.subscribe((payload) => {
            console.log('new message received. ', payload);
            this.currentMessage.next(payload);
        });
    }

    isNotificationChecked() {
        return Notification.permission !== 'default';
    }
}
