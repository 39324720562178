<mat-drawer-container class="dv-drawer-container">
  <mat-drawer-content class="dv-drawer-content">
    <div class="dv-drawer-content-data">
      <div class="responsive-table">
        <mat-table [dataSource]="transactionsDataSource"
                   class="mat-elevation-z8">
          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef>Date Time</mat-header-cell>
            <mat-cell
                    *matCellDef="let element"> {{element.item.created_at | date: 'short'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ref_type">
            <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
            <mat-cell
                    *matCellDef="let element"> {{element.item.ref_type}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef> Amount</mat-header-cell>
            <mat-cell
                    *matCellDef="let element"> {{(element.item.credit - element.item.debit) | number}} </mat-cell>
          </ng-container>


          <ng-container matColumnDef="after_balance">
            <mat-header-cell *matHeaderCellDef> After Balance
            </mat-header-cell>
            <mat-cell
                    *matCellDef="let element"> {{element.item.after_balance| number}} </mat-cell>
          </ng-container>

          <mat-header-row
                  *matHeaderRowDef="displayColumns"></mat-header-row>
          <mat-row
                  *matRowDef="let row; columns: displayColumns;"></mat-row>
        </mat-table>
      </div>
    </div>

    <mat-toolbar class="dv-drawer-content-footer">
      <mat-paginator
              [length]="pageLength"
              [pageIndex]="pageIndex"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="onPageChange($event)"
              showFirstLastButtons
      ></mat-paginator>
    </mat-toolbar>
  </mat-drawer-content>

</mat-drawer-container>



