import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  constructor() { }

  currentAccount = null;

  @Input()
  public set account(value) {
    this.currentAccount = value;
  }
  ngOnInit() {
  }

}
