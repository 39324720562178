<div fxLayout="column" fxFill class="incoming-container dv-customer-care dv-content">
    <br matLine>
    <mat-drawer-container class="dv-drawer-container">
        <mat-drawer-content class="dv-drawer-content">
            <div fxLayout="row" style="height: 100%;overflow: hidden">
                <div fxFlex="20" fxLayout="column">
                    <div class="incoming-list">
                        <div fxLayout="row center" style="padding: 0px 10px">
                            <button mat-icon-button color="primary" class="create-contact"
                                    title="Manual create a contact history" (click)="openCreateContactDialog()">
                                <mat-icon>add</mat-icon>
                                Create
                            </button>
                            <span fxFlex="1 1 0"></span>

                            <button mat-icon-button [matMenuTriggerFor]="filterMenu"
                                    [color]="filter.load == 'me' ? 'primary' : ''">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <mat-menu #filterMenu="matMenu">
                                <button mat-menu-item (click)="filterMe()">
                                    <mat-icon *ngIf="filter.load == 'me'">check</mat-icon>
                                    <mat-icon *ngIf="filter.load != 'me'"></mat-icon>
                                    <span>Assigned to me</span>
                                </button>
                                <button mat-menu-item (click)="filterOpen()">
                                    <mat-icon *ngIf="filter.load == 'open'">check</mat-icon>
                                    <mat-icon *ngIf="filter.load != 'open'"></mat-icon>
                                    <span>Open</span>
                                </button>
                                <button mat-menu-item (click)="filterAll()">
                                    <mat-icon *ngIf="filter.load == 'all'">check</mat-icon>
                                    <mat-icon *ngIf="filter.load != 'all'"></mat-icon>
                                    <span>All</span>
                                </button>
                            </mat-menu>

                        </div>
                        <div fxLayout="row start" style="padding: 0px 10px">
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="Type to search" [formControl]="searchContactFormControl">
                                <mat-hint *ngIf="searching">Searching...</mat-hint>
                            </mat-form-field>


                        </div>


                        <mat-list *ngIf="data">
                            <div *ngFor="let message of data.items"
                                 style="flex-direction: row; display: flex; justify-content: center; align-items: center; color: #99abb4">
                                <mat-list-item (click)="message.item.calling = false"
                                               [class.blink]="message.item.calling"
                                               [routerLink]="['/', 'customer-care', 'incoming', message.item.text_id]"
                                               routerLinkActive="selected">
                                    <mat-icon matListIcon
                                              *ngIf="message.item.contact_channel=='MOBILE_PHONE' || message.item.contact_channel=='HOME_PHONE' ">
                                        phone
                                    </mat-icon>
                                    <mat-icon matListIcon
                                              *ngIf="message.item.contact_channel!='MOBILE_PHONE' && message.item.contact_channel!='HOME_PHONE'">
                                        chat
                                    </mat-icon>
                                    <h3 matLine class="list-item"> {{message.item.contact}} </h3>

                                    <p matLine fxLayout="row">
                                        <span *ngIf="!message.item.calling && (message.item.contact_channel=='MOBILE_PHONE' || message.item.contact_channel=='HOME_PHONE')"
                                              class="sub-list-item"> {{message.item.created_at | date: 'dd/MM/yyyy h:mm a'}} </span>
                                        <span class="sub-list-item"
                                              *ngIf="message.item.calling && (message.item.contact_channel=='MOBILE_PHONE' || message.item.contact_channel=='HOME_PHONE') ">Đang gọi...</span>
                                        <span class="sub-list-item"
                                              *ngIf="message.item.contact_channel=='CHAT'">{{message.item.last_message}}</span>
                                        <span fxFlex="1 1 0"></span>
                                        <span *ngIf="message.item.status == 'closed'"
                                              class="sub-list-item">({{message.item.status}})</span>
                                    </p>
                                    <div *ngIf="message.item.assigned != null" matLine fxLayout="row" fxLayoutAlign="start center" class="assign-line m-t-10" style="margin-top: 10px">
                                        <div *ngIf="!message.item.assigned.avatar" class="assign-avatar" style="background: url('assets/images/users/default-avatar.png')">
                                        </div>
                                        <div *ngIf="message.item.assigned.avatar" class="assign-avatar" [ngStyle]="{background: 'url(' + message.item.assigned.avatar + ')'}">
                                        </div>
                                        <div class="assign-name m-l-10">
                                            {{message.item.assigned.full_name}}
                                        </div>
                                    </div>
                                </mat-list-item>
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="close(message.item)"
                                            *ngIf="message.item.status == 'active'">
                                        <mat-icon>close</mat-icon>
                                        <span>Close</span>
                                    </button>

                                    <button mat-menu-item (click)="reopen(message.item)"
                                            *ngIf="message.item.status == 'closed'">
                                        <mat-icon>undo</mat-icon>
                                        <span>Reopen</span>
                                    </button>
                                </mat-menu>

                            </div>
                            <mat-list-item *ngIf="!isFullDataLoaded()" (click)="loadMoreData()">
                                click here to load more
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
                <mat-divider [vertical]="true"></mat-divider>
                <div fxFlex="40" class="cs-container" fxLayout="column">
                    <div fxLayout="column" style="flex: 1 1 0">
                        <div>
                            <form>
                                <mat-form-field class="account-chip-list" appearance="outline">
                                    <mat-chip-list [selectable]="true" #chipList aria-label="Account selection">
                                        <mat-chip
                                                [value]="account"
                                                #chip="matChip"
                                                *ngFor="let account of accounts"
                                                [selectable]="true"
                                                [removable]="true"
                                                (removed)="removeAccount(account)"
                                                color="primary"
                                                (click)="chip.select()"
                                                (selectionChange)="selectionChange($event)"

                                        >
                                            {{ getProfileContact(account.profile) }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>

                                        <input
                                                placeholder="Type to search account"
                                                #accountInput
                                                [formControl]="accountFormControl"
                                                [matAutocomplete]="auto"
                                                [matChipInputFor]="chipList">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAccount($event)">
                                        <mat-option *ngFor="let profile of filteredProfiles"
                                                    [value]="profile">
                                            {{profile.profile.mobile_phone}} - {{profile.profile.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>


                            </form>
                        </div>

                        <div *ngIf="currentAccount" fxLayout="column" style="flex: 1 0 0">
                            <nav mat-tab-nav-bar>
                                <a mat-tab-link [active]="tabName === 'basic-info'"
                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'basic-info']">Summary</a>
                                <a mat-tab-link [active]="tabName === 'personal-info'"
                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'personal-info']">Personal</a>
                                <a mat-tab-link [active]="tabName === 'ticket'"
                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'ticket']">Ticket</a>
                                <a mat-tab-link [active]="tabName === 'transaction'"
                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'transaction']">Transaction</a>
<!--                                <a mat-tab-link [active]="tabName === 'call-history'"-->
<!--                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'call-history']">Call-->
<!--                                    History</a>-->
                                <a mat-tab-link [active]="tabName==='activity'"
                                   [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'activity']">Activity</a>
                            </nav>
                            <ng-container *ngIf="tabName==='basic-info'">
                                <div *ngIf="currentAccount" fxLayout="column" style="flex: 1 0 0">
                                    <div fxLayout="column" style="flex: 1 0 0; overflow: auto">
                                        <mat-card>
                                            <mat-card-header>
                                                <img mat-card-avatar [src]="currentAccount.profile.avatar"
                                                     *ngIf="currentAccount.profile.avatar"/>
                                                <img mat-card-avatar src="/assets/images/users/default-avatar.png"
                                                     *ngIf="!currentAccount.profile.avatar"/>
                                                <mat-card-title-group>
                                                    <mat-card-title>
                                                        {{currentAccount.profile.name}}
                                                        <mat-icon *ngIf="currentAccount.profile.kyc_level"
                                                                  style="cursor: default;" matTooltipPosition="after"
                                                                  matTooltip="KYC Level {{currentAccount.profile.kyc_level}}" class="text-success">
                                                            check_circle
                                                        </mat-icon>
                                                        <mat-icon *ngIf="!currentAccount.profile.kyc_level"
                                                                  style="cursor: default;" matTooltipPosition="after"
                                                                  matTooltip="KYC Level 0" class="text-muted">
                                                            check_circle
                                                        </mat-icon>
                                                    </mat-card-title>
                                                    <mat-card-subtitle>{{currentAccount.profile.email}} <span
                                                            *ngIf="currentAccount.profile.email && currentAccount.profile.mobile_phone"> - </span> {{currentAccount.profile.mobile_phone}}
                                                    </mat-card-subtitle>
                                                </mat-card-title-group>
                                                <div style="flex: 1 1 0;"></div>
                                                <button class="cs-btn"
                                                        [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'personal-info']"
                                                        mat-flat-button> Detail
                                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                                </button>

                                            </mat-card-header>
                                        </mat-card>
                                        <mat-card>


                                            <mat-card-content>

                                                <div fxLayout="row">
                                                    <mat-card-title>
                                                        {{currentAccount.wallet.available_balance | number}} {{currentAccount.wallet.currency}}
                                                    </mat-card-title>
                                                    <div style="flex: 1 1 0"></div>
                                                    <button [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'transaction']"
                                                            class="cs-btn" mat-flat-button>View all
                                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                                    </button>
                                                </div>

                                                <div class="responsive-table">
                                                    <mat-table [dataSource]="recentTransactionsDataSource"
                                                               class="mat-elevation-z8">
                                                        <ng-container matColumnDef="created_at">
                                                            <mat-header-cell *matHeaderCellDef>Date Time
                                                            </mat-header-cell>
                                                            <mat-cell
                                                                    *matCellDef="let element"> {{element.created_at | date: 'short'}} </mat-cell>
                                                        </ng-container>

                                                        <ng-container matColumnDef="ref_type">
                                                            <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                                                            <mat-cell
                                                                    *matCellDef="let element"> {{element.ref_type}} </mat-cell>
                                                        </ng-container>

                                                        <ng-container matColumnDef="amount">
                                                            <mat-header-cell *matHeaderCellDef> Amount</mat-header-cell>
                                                            <mat-cell
                                                                    *matCellDef="let element"> {{(element.credit - element.debit) | number}} </mat-cell>
                                                        </ng-container>


                                                        <ng-container matColumnDef="after_balance">
                                                            <mat-header-cell *matHeaderCellDef> After Balance
                                                            </mat-header-cell>
                                                            <mat-cell
                                                                    *matCellDef="let element"> {{element.after_balance| number}} </mat-cell>
                                                        </ng-container>

                                                        <mat-header-row
                                                                *matHeaderRowDef="recentTransactionDisplayColumns"></mat-header-row>
                                                        <mat-row
                                                                *matRowDef="let row; columns: recentTransactionDisplayColumns;"></mat-row>
                                                    </mat-table>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card>
                                            <mat-card-content>
                                                <div fxLayout="row">

                                                    <div class="cs-box-title">RECENT TICKETS</div>
                                                    <div style="flex: 1 0 0"></div>

                                                    <button (click)="openCreateTicketDialog()" mat-icon-button
                                                            title="Create ticket"
                                                            color="primary">
                                                        <mat-icon>add</mat-icon>

                                                    </button>

                                                    <button [routerLink]="['/', 'customer-care', 'incoming', detail.text_id, currentAccount.text_id, 'ticket']"
                                                            class="cs-btn" mat-flat-button>View all
                                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                                    </button>
                                                </div>
                                                <mat-divider></mat-divider>
                                                <app-ticket-summary [summary]="ticketSummary"></app-ticket-summary>

                                                <mat-divider></mat-divider>
                                                <div class="recent-tickets" fxLayout="column" fxLayoutAlign="start">
                                                    <div *ngFor="let ticket of recentTickets" fxLayout="column"
                                                         class="ticket-card">

                                                        <div fxLayout="row" fxLayoutAlign="start center">
                                                            <div class="ticket-title">
                                                                <a class="link" target="_blank" href="/ticket/{{ticket.text_id}}">{{ticket.title}}</a>
                                                            </div>
                                                            <span fxFlex="1 1 0%"></span>

                                                            <span>&nbsp;&nbsp;</span>
                                                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                                                <mat-icon>more_horiz</mat-icon>
                                                            </button>
                                                            <mat-menu #menu="matMenu">
                                                                <div mat-menu-item  *ngIf="ticket.status == 'new'">
                                                                    <button mat-raised-button color="primary" (click)="processTicket(ticket)"> Process</button>
                                                                </div>
                                                                <div mat-menu-item *ngIf="ticket.status == 'doing'">
                                                                    <button mat-raised-button color="primary" (click)="completeTicket(ticket)"> Complete</button>
                                                                </div>
                                                                <div mat-menu-item   *ngIf="ticket.status == 'doing'">
                                                                    <button mat-raised-button color="accent"  (click)="pauseTicket(ticket)"> Pause </button>
                                                                </div>
                                                                <div mat-menu-item  *ngIf="ticket.status == 'paused'">
                                                                    <button mat-raised-button color="accent" (click)="continueTicket(ticket)"> Continue</button>
                                                                </div>

                                                                <button mat-menu-item (click)="editTicket(ticket)">
                                                                    <mat-icon>edit</mat-icon>
                                                                    <span>Edit</span>
                                                                </button>

                                                            </mat-menu>
                                                        </div>
                                                        <div class="ticket-content" [innerHTML]="ticket.content">

                                                        </div>
                                                        <div fxLayout="row" class="ticket-footer"
                                                             fxLayoutAlign="center center">
                                                            <div>

                                                                <span class="label label-default"
                                                                      *ngIf="ticket.status == 'new'">Open</span>
                                                                <span class="label label-info"
                                                                      *ngIf="ticket.status == 'doing'">Doing</span>
                                                                <span class="label label-warning"
                                                                      *ngIf="ticket.status == 'paused'">Paused</span>
                                                                <span class="label label-warning"
                                                                      *ngIf="ticket.status == 'pending'">Pending</span>
                                                                <span class="label label-success"
                                                                      *ngIf="ticket.status == 'completed'">Completed</span>
                                                                <span class="label label-danger"
                                                                      *ngIf="ticket.status == 'cancelled'">Cancelled</span>
                                                                <span class="label label-success"
                                                                      *ngIf="ticket.status == 'closed'">Closed</span>
                                                            </div>
                                                            <span fxFlex="1 1 0%">
                                                            </span>
                                                            <span class="ticket-date">{{ticket.created_at | date: 'dd/MM/yyyy h:mm a'}}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </mat-card-content>

                                        </mat-card>
                                    </div>

                                </div>
                            </ng-container>
                            <ng-container *ngIf="tabName==='personal-info'">
                                <app-personal-info [account]="currentAccount"></app-personal-info>
                            </ng-container>

                            <ng-container *ngIf="tabName==='transaction'">
                                <app-transactions [account]="currentAccount"></app-transactions>
                            </ng-container>

                            <ng-container *ngIf="tabName==='ticket'">
                                <app-ticket-summary [summary]="ticketSummary"></app-ticket-summary>
                                <app-tickets [account]="currentAccount"></app-tickets>
                            </ng-container>

                            <ng-container *ngIf="tabName==='call-history'">
                                <app-call-history [account]="currentAccount"></app-call-history>
                            </ng-container>

                            <ng-container *ngIf="tabName==='activity'">
                                <app-activity [account]="currentAccount"></app-activity>
                            </ng-container>


                        </div>
                    </div>
                </div>
                <mat-divider [vertical]="true"></mat-divider>
                <div fxFlex="40" fxLayout="column">

                    <mat-card>
                        <mat-card-content>
                            <div fxLayout="row" fxLayoutAlign="start">
                                <div class="cs-box-title m-b-20">CALL INFORMATION</div>
                                <div fxFlex="1 1 0%"></div>


                                <mat-checkbox [(ngModel)]="hideCallInfo">Ẩn</mat-checkbox>
                            </div>


                            <ng-container *ngIf="!hideCallInfo">
                                <mat-chip-list class="cs-tags" multiple>
                                    <mat-chip #chip=matChip *ngFor="let tag of allTags" (click)="tagClick(chip)"
                                              [color]="tag.color"
                                              [selected]="tag.selected" [value]="tag">{{tag.display}}</mat-chip>
                                </mat-chip-list>

                                <div *ngIf="detail">

                                    <mat-form-field appearance="outline">
                                        <label><b>Note</b></label>
                                        <textarea matInput [formControl]="noteFormControl" [value]="detail.note"
                                                  rows="6"></textarea>
                                        <mat-hint *ngIf="noteSaved">Saved
                                            <mat-icon>check</mat-icon>
                                        </mat-hint>
                                        <mat-hint *ngIf="noteSaving"><i>Saving</i>
                                            <mat-icon>check</mat-icon>
                                        </mat-hint>
                                    </mat-form-field>


                                </div>
                            </ng-container>

                        </mat-card-content>
                    </mat-card>

                    <mat-card class="fl-col fl" *ngIf="detail && currentAccount">
                        <mat-card-content class="fl-col fl">
                            <div class="cs-box-title m-b-20">CHAT</div>
                            <div class="chat-msg fl"
                                 style="overflow: auto; background: url('/assets/images/background/background-chat.png'); padding: 10px"
                                 #scrollMe [scrollTop]="scrollMe.scrollHeight">


                                <div *ngFor="let group of messageGroups">
                                    <div class="chat-date" *ngIf="group.userType == 'date'">
                                        <div class="chat-date-item">{{group.date | date: 'shortDate' }}</div>
                                    </div>
                                    <div class="chat-date" *ngIf="group.userType == 'system' || group.userType == 'join'">
                                        <div class="chat-date-item">{{group.messages[0].message}}
                                        </div>
                                    </div>
                                    <app-sent-msg *ngIf="(group.userType == 'backend_user' || group.type == 'me') && group.userType != 'join' && group.userType != 'system'"
                                                  [group]="group"></app-sent-msg>
                                    <app-received-msg *ngIf="group.userType=='app_user'"
                                                      [group]="group"></app-received-msg>

                                </div>
                            </div>

                           <div class="position-relative">
                               <div class="action-line" style="border-top: rgba(0,0,0,0.12) 1px solid">
                                   <button onclick="image_file.click()" mat-icon-button>
                                       <mat-icon>image</mat-icon>
                                       <input type="file" (change)="onImageUpload($event)" id="image_file"
                                              style="display: none">
                                   </button>
                                   <button mat-icon-button>
                                       <mat-icon>attach_file</mat-icon>
                                   </button>
                                   <span style="flex: 1 1 0"></span>
                                   <mat-checkbox *ngIf="currentAccount.conversation" (change)="changeChatConfig($event)"
                                                 [(ngModel)]="currentAccount.conversation.allow_send_media">Allow this user
                                       to send images
                                   </mat-checkbox>
                               </div>
                               <div class="chat-input">
                                   <mat-form-field appearance="outline">
                                    <textarea style="resize: none" placeholder="Write your message"
                                              [(ngModel)]="msgText" (keypress)="chatPressMsg($event)"
                                              matInput></textarea>
                                   </mat-form-field>
                               </div>
                               <div class="chat-block" *ngIf="detail?.assigned_to_me === false">
                                   <button class="assign-to-me-btn" (click)="assignToMe(contactHistoryId)" mat-raised-button color="accent">Assign to me</button>

                               </div>
                           </div>
                        </mat-card-content>
                    </mat-card>

                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>


</div>
