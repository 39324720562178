import {NgModule} from '@angular/core';
import {IncomingComponent} from './incoming.component';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AllMaterialModule} from '../../all-material-module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CreateTicketDialogComponent} from './create-ticket-dialog/create-ticket-dialog.component';
import {EditorModule} from '@tinymce/tinymce-angular';

import {TransactionsComponent} from './transactions/transactions.component';
import {TicketsComponent} from './tickets/tickets.component';
import {CallHistoryComponent} from './call-history/call-history.component';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {CreateContactDialogComponent} from './create-contact-dialog/create-contact-dialog.component';
import {SentMsgComponent} from './sent-msg/sent-msg.component';
import {ReceivedMsgComponent} from './received-msg/received-msg.component';
import {UploadMsgComponent} from './upload-msg/upload-msg.component';
import {MatSelectModule} from '@angular/material';
import {TicketSummaryComponent} from './ticket-summary/ticket-summary.component';
import {ActivityComponent} from './activity/activity.component';


@NgModule({
    declarations: [
        IncomingComponent,
        CreateTicketDialogComponent,
        PersonalInfoComponent,
        TransactionsComponent,
        TicketsComponent,
        CallHistoryComponent,
        CreateContactDialogComponent,
        SentMsgComponent,
        ReceivedMsgComponent,
        UploadMsgComponent,
        TicketSummaryComponent,
        ActivityComponent
    ],
    exports: [
        SentMsgComponent,
        ReceivedMsgComponent,
        UploadMsgComponent,
        TicketSummaryComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        AllMaterialModule,
        SharedModule,
        RouterModule,
        EditorModule,
        MatSelectModule,
    ],
    entryComponents: [
        CreateTicketDialogComponent,
        CreateContactDialogComponent,
        SentMsgComponent,
        ReceivedMsgComponent,
        UploadMsgComponent
    ]
})
export class IncomingModules {
}
