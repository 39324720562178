import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(
        private httpClient: HttpClient,
        private handler: HttpBackend,
    ) {
    }

    getUrl() {
        const url = environment.api_base_url + '/upload/get-url';

        return this.httpClient.get(url
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }

    upload(url, file) {
        const client = new HttpClient(this.handler);
        const formData = new FormData();
        formData.append('file', file);
        return client.post(url, formData, {
            reportProgress: true,
            observe: 'events'
        });


        // const xhr = new XMLHttpRequest();
        //
        // xhr.addEventListener('progress', function (e) {
        //     console.log(e);
        // }, false);
        // if (xhr.upload) {
        //     xhr.upload.onprogress = function (e) {
        //         console.log(e);
        //     };
        // }
        // xhr.onreadystatechange = function (e) {
        //
        // };
        // xhr.open('post', url, true);
        // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        //
        // const formData = new FormData();
        // formData.append(file, file);
        // xhr.send(formData);
        // xhr.send();
        // return of(1);
    }
}
