import {Injectable} from '@angular/core';
import {WebSocketSubject} from 'rxjs/webSocket';
import {Subject} from 'rxjs';
import {IResponse} from '../../shared/interfaces/response';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export const operations = {
    'register': 'register',
    'getToken': 'getToken',
    'getTokenResult': 'getTokenResult',
    'notify': 'notify',
};

export const connectionStatus = {
    'connected': 'connected',
    'closing': 'closing',
    'closed': 'closed',
    'maxAttemptsReached': 'maxAttemptsReached'
};

@Injectable({
    providedIn: 'root'
})
export class DcmClientService {
    private socketSubject: WebSocketSubject<any>;
    private __tokenData;
    private reconnectAttemptCount = 0;

    public onTokenReceivedSubject: Subject<any> = new Subject<any>();
    public onMessageReceivedSubject: Subject<any> = new Subject<any>();
    public onConnectionStatusChangedSubject: Subject<any> = new Subject<any>();

    private readonly appId: string;
    private readonly useLoadBalancer: boolean;
    private readonly loadBalancerUrl: string;
    private connectionString: string;

    private readonly autoReconnect: boolean;
    private readonly reconnectInterval: number;
    private readonly reconnectMaxAttempts: number;

    get tokenData() {
        return this.__tokenData;
    }

    private registerSocketEvents() {
        const that = this;
        this.socketSubject = new WebSocketSubject<any>({
            url: this.connectionString,
            openObserver: {
                next(value: any) {
                    console.log('DEBUG: socket connected');
                    that.reconnectAttemptCount = 0;
                    that.onConnectionStatusChangedSubject.next({
                        status: connectionStatus.connected,
                        data: value
                    });
                }
            },
            closeObserver: {
                next(value: any) {
                    console.log('DEBUG: socket closed');
                    if (that.socketSubject !== null && that.socketSubject !== undefined) {
                        that.socketSubject.unsubscribe();
                    }
                    that.__tokenData = null;
                    that.onConnectionStatusChangedSubject.next({
                        status: connectionStatus.closed,
                        data: value
                    });

                    that.reconnect();
                }
            },
            closingObserver: {
                next(value: any) {
                    console.log('DEBUG: socket closing');
                    that.onConnectionStatusChangedSubject.next({
                        status: connectionStatus.closing,
                        data: value
                    });
                }
            },
        });
        this.socketSubject.subscribe(
            (message) => {
                switch (message.operation) {
                    case operations.getTokenResult:
                        console.log('DEBUG: dcm client service - token result received');
                        console.log(message.data.token);
                        this.__tokenData = {
                            token: message.data.token,
                            expiredAt: message.data.expiredAt
                        };
                        this.onTokenReceivedSubject.next(message.data);
                        break;
                    case operations.notify:
                        console.log('DEBUG: dcm client service - notify message received');
                        this.onMessageReceivedSubject.next(message.data);
                        break;
                    default:
                        console.log('DEBUG: dcm client service - other message received ', message.operation);
                        break;
                }
            }, // Called whenever there is a message from the server.
            (err) => {
                if (err instanceof CloseEvent) {
                    this.onConnectionStatusChangedSubject.next(connectionStatus.closed);
                }
            }, // Called if at any point WebSocket API signals some kind of error.
            () => {
            } // Called when connection is closed (for whatever reason).
        );
    }


    constructor(
        private httpClient: HttpClient
    ) {
        const config = environment.dwsConfig.dcmConfig;
        this.appId = config.appId;
        this.useLoadBalancer = config.useLoadBalancer;
        this.loadBalancerUrl = config.loadBalancerUrl;
        this.connectionString = config.connectionString;
        this.autoReconnect = config.autoReconnect;
        this.reconnectInterval = config.reconnectInterval;
        this.reconnectMaxAttempts = config.reconnectMaxAttempts;
        this.registerEvent();
    }

    private registerEvent() {
        if (this.useLoadBalancer === true) {
            this.httpClient.get(this.loadBalancerUrl).subscribe(
                (response: IResponse) => {
                    if (!response.success) {
                        this.reconnect();
                    } else {
                        this.connectionString = response.data.connectionString;
                        this.registerSocketEvents();
                    }
                },
                (error) => {
                    this.reconnect();
                },
                () => {
                }
            );
        } else {
            this.registerSocketEvents();
        }
    }

    private reconnect() {
        if (this.autoReconnect) {
            if (this.reconnectAttemptCount > this.reconnectMaxAttempts) {
                console.log('Reconnect max attempts reached');
                this.onConnectionStatusChangedSubject.next({
                    status: connectionStatus.maxAttemptsReached
                });
            } else {
                this.reconnectAttemptCount++;
                console.log(`Socket reconnecting... {${this.reconnectAttemptCount.toString()}}`);
                setTimeout(() => {
                    this.registerEvent();
                }, 5000);
            }
        }
    }


    public registerToken() {
        console.log('DEBUG: dcm client service - registerToken');
        const data = {
            appId: this.appId
        };
        this.__sendMessage(operations.register, data);
    }

    public getToken() {
        console.log('DEBUG: dcm client service - getToken');
        const data = {
            appId: this.appId,
        };
        if (this.__tokenData !== null && this.__tokenData !== undefined) {
            data['currentToken'] = this.__tokenData.token;
        }
        this.__sendMessage(operations.getToken, data);
    }

    private __sendMessage(operation, data) {
        if (!this.socketSubject) {
            return;
        }

        const payload = {
            operation: operation,
            data: data
        };
        this.socketSubject.next(payload);
    }
}
