import {Component, Input, OnInit} from '@angular/core';

import {MatTableDataSource, PageEvent} from '@angular/material';
import {CustomerCareService} from '../../customer-care.service';
import {IResponse} from '../../../shared/interfaces/response';
import {ContactHistoryFilter} from '../../filters';

@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.css']
})
export class CallHistoryComponent implements OnInit {

  currentAccount = null;
  filter = new ContactHistoryFilter();
  histories = [];
  historiesDataSource = new MatTableDataSource(this.histories);

  displayColumns = [
    'created_at',
    'contact',
    'contact_channel',
    'assigned_to'
  ];

  pageLength = 0;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 50];

  ngOnInit(): void {
  }


  @Input()
  public set account(value) {
    if (value !== this.currentAccount) {
      this.currentAccount = value;
      this.filter.offset = 0;
      this.filter.limit = this.pageSize;
      this.getData();
    }
  }

  constructor(
    private customerCareService: CustomerCareService
  ) {
  }

  getData() {
    this.customerCareService.getContactHistoryListForAccount(this.currentAccount.text_id, this.filter).subscribe((response: IResponse) => {
      if (response.success) {
        this.histories = response.data.items;
        this.historiesDataSource = new MatTableDataSource(this.histories);
        this.pageLength = response.data.pagination.total;
      }
    });
  }

  public onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.filter.offset = this.pageIndex * this.pageSize;
    this.filter.limit = this.pageSize;
    this.getData();
  }

}
