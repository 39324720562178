import {Component, Input, OnInit} from '@angular/core';
import {CustomerCareService} from '../../customer-care.service';
import {IResponse} from '../../../shared/interfaces/response';
import {MatDialog, MatTableDataSource, PageEvent} from '@angular/material';
import {TicketFilter} from '../../filters';
import {CreateTicketDialogComponent} from '../create-ticket-dialog/create-ticket-dialog.component';
import {TicketService} from '../../ticket-service';


@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  currentAccount = null;
  filter = new TicketFilter();
  tickets = [];
  ticketsDataSource = new MatTableDataSource(this.tickets);

  displayColumns = [
    'title',
    'status',
    'actions'
  ];

  pageLength = 0;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 50];


  @Input()
  public set account(value) {
    if (value !== this.currentAccount) {
      this.currentAccount = value;
      this.filter.offset = 0;
      this.filter.limit = this.pageSize;
      this.getData();
    }
  }

  constructor(
    private customerCareService: CustomerCareService,
    private createTicketDialog: MatDialog,
    private ticketService: TicketService
  ) {
  }

  markDoneTicket(ticket) {
    this.customerCareService.markDoneTicket(ticket.text_id).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = 'done';
      }
    });

  }
  openCreateTicketDialog() {
    const dialogRef = this.createTicketDialog.open(CreateTicketDialogComponent, {
      data: {
        account_id: this.currentAccount.text_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }


  editTicket(ticket) {
    const dialogRef = this.createTicketDialog.open(CreateTicketDialogComponent, {
      data: {
        account_id: this.currentAccount.text_id,
        ticket: ticket
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        for (const attr in res) {
          if (res[attr]) {
            ticket[attr] = res[attr];
          }
        }
      }
    });
  }

  deleteTicket(ticket) {
    this.customerCareService.deleteTicket(ticket.text_id).subscribe((res: IResponse) => {
      if (res.success) {
        this.customerCareService.recentTickets(this.currentAccount.text_id).subscribe((rs: IResponse) => {
          this.getData();
        });
      }
    });
  }

  processTicket(ticket) {
    this.ticketService.patch(ticket.text_id, {status: 'doing'}).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = res.data.status;
      }
    });
  }

  pauseTicket(ticket) {
    this.ticketService.patch(ticket.text_id, {status: 'paused'}).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = res.data.status;
      }
    });
  }
  completeTicket(ticket) {
    this.ticketService.patch(ticket.text_id, {status: 'completed'}).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = res.data.status;
      }
    });
  }
  continueTicket(ticket) {
    this.ticketService.patch(ticket.text_id, {status: 'doing'}).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = res.data.status;
      }
    });
  }


  closeTicket(ticket) {
    this.ticketService.patch(ticket.text_id, {status: 'closed'}).subscribe((res: IResponse) => {
      if (res.success) {
        ticket.status = res.data.status;
      }
    });
  }

  getData() {
    this.customerCareService.getTickets(this.currentAccount.text_id, this.filter).subscribe((response: IResponse) => {
      if (response.success) {
        this.tickets = response.data.items;
        this.ticketsDataSource = new MatTableDataSource(this.tickets);
        this.pageLength = response.data.pagination.total;
      }
    });
  }

  public onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.filter.offset = this.pageIndex * this.pageSize;
    this.filter.limit = this.pageSize;
    this.getData();
  }


  ngOnInit() {
  }

}
