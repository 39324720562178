<mat-drawer-container class="dv-drawer-container">
  <mat-drawer-content class="dv-drawer-content">
    <ng-container *ngIf="currentActivity === null">
      <div class="dv-drawer-content-data">
        <div style="font-size: 12px">
          <form [formGroup]="filterForm">
            <div class="d-flex">
              <mat-form-field class="m-r-10">
                <input matInput placeholder="Search transaction code" formControlName="search">
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div class="d-flex">
              <mat-form-field class="m-r-10">
                <mat-label>Ref Type</mat-label>
                <mat-select formControlName="ref_type" placeholder="Search by type">
                  <mat-option value="">All</mat-option>
                  <mat-option value="DEPOSIT">Deposit</mat-option>
                  <mat-option value="TRANSFER">Transfer</mat-option>
                  <mat-option value="WITHDRAW">Withdraw</mat-option>
                  <mat-option value="RECEIVE">Receive</mat-option>
                  <mat-option value="B2W_TRANSFER">B2W Transfer </mat-option>
                  <mat-option value="B2W_RECEIVE">B2W Receive</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="m-r-10">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" placeholder="Search Status">
                  <mat-option value="">All</mat-option>
                  <mat-option value="CLOSED">Closed</mat-option>
                  <mat-option value="PROCESSING">Processing</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="m-r-10">
                <mat-label>Result</mat-label>
                <mat-select formControlName="result" placeholder="Search Result">
                  <mat-option value="">All</mat-option>
                  <mat-option value="COMPLETED">Completed</mat-option>
                  <mat-option value="DENIED">Denied</mat-option>
                  <mat-option value="CANCELLED">Cancelled</mat-option>

                </mat-select>
              </mat-form-field>
            </div>

          </form>
        </div>

        <div class="responsive-table">
          <mat-table [dataSource]="dataSource"
                     class="mat-elevation-z8">
            <ng-container matColumnDef="content">
              <mat-header-cell *matHeaderCellDef>Detail</mat-header-cell>
              <mat-cell *matCellDef="let element" class="flex-grow-1">

                <div class="d-flex flex-1 align-items-center detail-item item-transfer" *ngIf="element.ref_type === 'TRANSFER'">
                  <div class="avatar-container">
                    <div class="avatar" *ngIf="!element.transferactivity.to_account.profile.avatar" style="background-image: url(assets/images/users/default-avatar.png)"></div>
                    <div class="avatar" *ngIf="element.transferactivity.to_account.profile.avatar" [ngStyle]="{'background-image': 'url(' + element.transferactivity.to_account.profile.avatar + ')'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">
                    <span class="name"><span class="text-warning">Transfer</span> to {{element.transferactivity.to_account.profile.name}}</span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>
                    </span>
                  </div>

                  <div class="d-flex flex-column money-container">
                    <div class="money money-red">-{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>


                </div>

                <div class="d-flex flex-1 align-items-center detail-item item-deposit" *ngIf="element.ref_type === 'DEPOSIT'">
                  <div class="avatar-container">

                    <div class="avatar"  [ngStyle]="{'background-image': 'url(assets/images/logo_banks/' + element.depositactivity.bank + '.png)'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">

                    <span class="name"><span class="text-info">Deposit</span> from {{element.depositactivity.bank}} </span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>
                    </span>
                  </div>

                  <div class="d-flex flex-column money-container">
                    <div class="money" [ngClass]="element.result==='COMPLETED' ? 'money-green' : ''">{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>
                </div>

                <div class="d-flex flex-1 align-items-center detail-item item-withdraw" *ngIf="element.ref_type === 'WITHDRAW'">
                  <div class="avatar-container">

                    <div class="avatar"  [ngStyle]="{'background-image': 'url(assets/images/logo_banks/' + element.withdrawactivity.bank + '.png)'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">

                    <span class="name"><span class="text-info">Withdraw</span> to {{element.withdrawactivity.bank}} </span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>
                    </span>
                  </div>

                  <div class="d-flex flex-column money-container">
                    <div class="money" [ngClass]="element.result==='COMPLETED' ? 'money-red' : ''">{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>

                </div>

                <div class="d-flex flex-1 align-items-center detail-item item-b2w-receive" *ngIf="element.ref_type === 'B2W_RECEIVE'">
                  <div class="avatar-container">
                    <div class="avatar" *ngIf="!element.banktowalletreceiveractivity.from_account.profile.avatar" style="background-image: url(assets/images/users/default-avatar.png)"></div>
                    <div class="avatar" *ngIf="element.banktowalletreceiveractivity.from_account.profile.avatar" [ngStyle]="{'background-image': 'url(' + element.banktowalletreceiveractivity.from_account.profile.avatar + ')'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">

                    <span class="name"><span class="text-success">B2W Receive</span> from {{element.banktowalletreceiveractivity.from_account.profile.name}} </span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>
                    </span>
                  </div>

                  <div class="d-flex flex-column money-container">
                    <div class="money" [ngClass]="element.result==='COMPLETED' ? 'money-green' : ''">{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>

                </div>

                <div class="d-flex flex-1 align-items-center detail-item item-receive" *ngIf="element.ref_type === 'RECEIVE'">
                  <div class="avatar-container">
                    <div class="avatar" *ngIf="!element.receiveractivity.from_account.profile.avatar" style="background-image: url(assets/images/users/default-avatar.png)"></div>
                    <div class="avatar" *ngIf="element.receiveractivity.from_account.profile.avatar" [ngStyle]="{'background-image': 'url(' + element.receiveractivity.from_account.profile.avatar + ')'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">

                    <span class="name"><span class="text-success">Receive</span> from {{element.receiveractivity.from_account.profile.name}} </span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>
                    </span>
                  </div>

                  <div class="d-flex flex-column money-container">
                    <div class="money" [ngClass]="element.result==='COMPLETED' ? 'money-green' : ''">{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>
                </div>

                <div class="d-flex flex-1 align-items-center detail-item item-b2w-transfer" *ngIf="element.ref_type === 'B2W_TRANSFER'">
                  <div class="avatar-container">
                    <div class="avatar" *ngIf="!element.banktowallettransferactivity.to_account.profile.avatar" style="background-image: url(assets/images/users/default-avatar.png)"></div>
                    <div class="avatar" *ngIf="element.banktowallettransferactivity.to_account.profile.avatar" [ngStyle]="{'background-image': 'url(' + element.banktowallettransferactivity.to_account.profile.avatar + ')'}"></div>
                  </div>

                  <div class="d-flex flex-column flex-grow-1 flex-shirk-1">

                    <span class="name"><span class="text-warning">B2W Transfer</span> to {{element.banktowallettransferactivity.to_account.profile.name}} </span>
                    <span class="name">From {{element.banktowallettransferactivity.bank}}</span>
                    <span class="name">
                      <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                      <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                      <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>

                      <span class="label label-success" *ngIf="element.status === 'CLOSED'">Closed</span>
                      <span class="label label-warning" *ngIf="element.status === 'PROCESSING'">Processing</span>

                    </span>
                  </div>
                  <div class="d-flex flex-column money-container">
                    <div class="money" [ngClass]="element.result==='COMPLETED' ? 'money-red' : ''">{{element.amount | number: '1.0-0'}} {{element.currency}}</div>
                    <span class="name date">{{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</span>
                  </div>
                </div>

              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="completed_at">
              <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.completed_at | date: 'dd/MM/yyyy hh:mm a'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="result">
              <mat-header-cell *matHeaderCellDef>Result</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="label label-success" *ngIf="element.result === 'COMPLETED'">Completed</span>
                <span class="label label-danger" *ngIf="element.result === 'DENIED'">Denied</span>
                <span class="label label-warning" *ngIf="element.result === 'CANCELLED'">Cancelled</span>
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="button-row">
                  <button mat-icon-button (click)="setCurrentActivity(element)">
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
                    *matHeaderRowDef="displayColumns"></mat-header-row>
            <mat-row
                    *matRowDef="let row; columns: displayColumns;"></mat-row>
          </mat-table>
        </div>
      </div>

      <mat-toolbar class="dv-drawer-content-footer">
        <mat-paginator
                [length]="pageLength"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="onPageChange($event)"
                showFirstLastButtons
        ></mat-paginator>
      </mat-toolbar>
    </ng-container>

    <ng-container *ngIf="currentActivity != null">
      <button mat-icon-button (click)="currentActivity=null">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <div>
       <div class="property">
         <div class="property-label">ID</div>
         <div class="property-value">{{currentActivity.id}}</div>
       </div>

        <div class="property">
          <div class="property-label">Code</div>
          <div class="property-value">{{currentActivity.code}}</div>
        </div>

        <div class="property">
          <div class="property-label">Amount</div>
          <div class="property-value">{{currentActivity.amount | number: '1.0-0'}} {{currentActivity.currency}}</div>
        </div>

        <div class="property">
          <div class="property-label">Status</div>
          <div class="property-value">{{currentActivity.status}}</div>
        </div>

        <div class="property">
          <div class="property-label">Result</div>
          <div class="property-value">
            <span class="label label-success" *ngIf="currentActivity.result === 'COMPLETED'">Completed</span>
            <span class="label label-danger" *ngIf="currentActivity.result === 'DENIED'">Denied</span>
            <span class="label label-warning" *ngIf="currentActivity.result === 'CANCELLED'">Cancelled</span>
          </div>
        </div>

        <div class="property">
          <div class="property-label">Note</div>
          <div class="property-value">{{currentActivity.note}}</div>
        </div>

        <div class="property">
          <div class="property-label">Created at</div>
          <div class="property-value">{{currentActivity.created_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
        </div>

        <div class="property">
          <div class="property-label">Updated at</div>
          <div class="property-value">{{currentActivity.updated_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
        </div>

        <div class="property">
          <div class="property-label">Completed at</div>
          <div class="property-value">{{currentActivity.completed_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
        </div>

        <div class="property">
          <div class="property-label">REF type</div>
          <div class="property-value">{{currentActivity.ref_type}}</div>
        </div>

        <div class="property">
          <div class="property-label">REF ID</div>
          <div class="property-value">{{currentActivity.ref_id}}</div>
        </div>

        <div class="property">
          <div class="property-label">Is Recipient Confirm</div>
          <div class="property-value">{{currentActivity.is_recipient_confirm}}</div>
        </div>

        <div class="property">
          <div class="property-label">Payment Category Level1</div>
          <div class="property-value">{{currentActivity.payment_category_level1}}</div>
        </div>


        <ng-container *ngIf="currentActivity.ref_type === 'B2W_TRANSFER'">
          <p>TO ACCOUNT INFORMATION</p>
          <div class="property">
            <div class="property-label">Text ID</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.text_id}}</div>
          </div>

          <div class="property">
            <div class="property-label">Account type</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.business_type}}</div>
          </div>

          <div class="property">
            <div class="property-label">Name</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.name}}</div>
          </div>

          <div class="property">
            <div class="property-label">Phone</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.mobile_phone}}</div>
          </div>

          <div class="property">
            <div class="property-label">Email</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.email}}</div>
          </div>

          <div class="property">
            <div class="property-label">KYC level</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.kyc_level}}</div>
          </div>

          <div class="property">
            <div class="property-label">Created at</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.created_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>

          <div class="property">
            <div class="property-label">Identity number</div>
            <div class="property-value">{{currentActivity.banktowallettransferactivity.to_account.profile.identity_id | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>


        </ng-container>

        <ng-container *ngIf="currentActivity.ref_type === 'TRANSFER'">
          <p>TO ACCOUNT INFORMATION</p>
          <div class="property">
            <div class="property-label">Text ID</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.text_id}}</div>
          </div>

          <div class="property">
            <div class="property-label">Account type</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.business_type}}</div>
          </div>

          <div class="property">
            <div class="property-label">Name</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.name}}</div>
          </div>

          <div class="property">
            <div class="property-label">Phone</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.mobile_phone}}</div>
          </div>

          <div class="property">
            <div class="property-label">Email</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.email}}</div>
          </div>

          <div class="property">
            <div class="property-label">KYC level</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.kyc_level}}</div>
          </div>

          <div class="property">
            <div class="property-label">Created at</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.created_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>

          <div class="property">
            <div class="property-label">Identity number</div>
            <div class="property-value">{{currentActivity.transferactivity.to_account.profile.identity_id | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentActivity.ref_type === 'B2W_RECEIVE'">
          <p>FROM ACCOUNT INFORMATION</p>
          <div class="property">
            <div class="property-label">Text ID</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.text_id}}</div>
          </div>

          <div class="property">
            <div class="property-label">Account type</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.business_type}}</div>
          </div>

          <div class="property">
            <div class="property-label">Name</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.name}}</div>
          </div>

          <div class="property">
            <div class="property-label">Phone</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.mobile_phone}}</div>
          </div>

          <div class="property">
            <div class="property-label">Email</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.email}}</div>
          </div>

          <div class="property">
            <div class="property-label">KYC level</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.kyc_level}}</div>
          </div>

          <div class="property">
            <div class="property-label">Created at</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.created_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>

          <div class="property">
            <div class="property-label">Identity number</div>
            <div class="property-value">{{currentActivity.banktowalletreceiveractivity.from_account.profile.identity_id | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentActivity.ref_type === 'RECEIVE'">
          <p>FROM ACCOUNT INFORMATION</p>
          <div class="property">
            <div class="property-label">Text ID</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.text_id}}</div>
          </div>

          <div class="property">
            <div class="property-label">Account type</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.business_type}}</div>
          </div>

          <div class="property">
            <div class="property-label">Name</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.name}}</div>
          </div>

          <div class="property">
            <div class="property-label">Phone</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.mobile_phone}}</div>
          </div>

          <div class="property">
            <div class="property-label">Email</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.email}}</div>
          </div>

          <div class="property">
            <div class="property-label">KYC level</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.kyc_level}}</div>
          </div>

          <div class="property">
            <div class="property-label">Created at</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.created_at | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>

          <div class="property">
            <div class="property-label">Identity number</div>
            <div class="property-value">{{currentActivity.receiveractivity.from_account.profile.identity_id | date: 'dd/MM/yyyy hh:mm a'}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentActivity.ref_type === 'DEPOSIT'">
          <p>BANK INFORMATION</p>
          <div class="property">
            <div class="property-label">Bank name</div>
            <div class="property-value">{{currentActivity.depositactivity.bank}}</div>
          </div>

          <ng-container *ngIf="currentActivity.ref?.vietbankdepositrequest">
            <p>VIETBANK DEPOSIT REQUEST</p>
            <div class="property">
              <div class="property-label">Result</div>
              <div class="property-value">{{currentActivity.ref.vietbankdepositrequest.result}}</div>
            </div>

            <div class="property">
              <div class="property-label">Note</div>
              <div class="property-value">{{currentActivity.ref.vietbankdepositrequest.note}}</div>
            </div>

            <div class="property">
              <div class="property-label">Wrong OTP times</div>
              <div class="property-value">{{currentActivity.ref.vietbankdepositrequest.wrong_otp_time}}</div>
            </div>

            <div class="property">
              <div class="property-label">Request Log Status</div>
              <div class="property-value">{{currentActivity.ref.vietbankdepositrequest.request_log_status}}</div>
            </div>

            <div class="property">
              <div class="property-label">Status</div>
              <div class="property-value">{{currentActivity.ref.vietbankdepositrequest.status}}</div>
            </div>

            <p>VIETBANK DEPOSIT REQUEST API</p>
            <div *ngFor="let api of currentActivity.ref.vietbankdepositrequest.api">
              <div class="property">
                <div class="property-label">Function name</div>
                <div class="property-value">{{api.function_name}}</div>
              </div>
              <div class="property">
                <div class="property-label">Response code</div>
                <div class="property-value">{{api.response_code}}</div>
              </div>

              <div class="property">
                <div class="property-label">HTTP Response code</div>
                <div class="property-value">{{api.http_response_code}}</div>
              </div>
              <br>
            </div>

          </ng-container>




        </ng-container>

        <ng-container *ngIf="currentActivity.ref_type === 'WITHDRAW'">
          <p>BANK INFORMATION</p>
          <div class="property">
            <div class="property-label">Bank name</div>
            <div class="property-value">{{currentActivity.withdrawactivity.bank}}</div>
          </div>


        </ng-container>

      </div>

    </ng-container>

  </mat-drawer-content>

</mat-drawer-container>
