import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomerCareService} from '../../customer-care.service';
import {IResponse} from '../../../shared/interfaces/response';
import {TicketService} from '../../../ticket/ticket-service';


@Component({
    selector: 'app-create-ticket-dialog',
    templateUrl: './create-ticket-dialog.component.html',
    styleUrls: ['./create-ticket-dialog.component.css']
})
export class CreateTicketDialogComponent implements OnInit {
    @HostBinding('class') classes = 'diva dv-list-page';

    form: FormGroup;
    ticket = null;
    users = [];
    categories = [];

    customerRequests = [];
    loading = false;
    allChannel = {
        email: 'Email',
        call: 'Call',
        chat: 'Chat',
        other: 'Other',
        '': ''
    };

    constructor(
        public dialogRef: MatDialogRef<CreateTicketDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private service: CustomerCareService,
        private ticketService: TicketService,
        private dialog: MatDialog
    ) {

    }

    ngOnInit() {
        if (this.data.ticket) {
            this.ticket = this.data.ticket;
        }
        this.form = this.fb.group({
            title: [this.ticket ? this.ticket.title : null, Validators.required],
            content: [this.ticket ? this.ticket.content : null, null],
            deadline: [this.ticket ? this.ticket.deadline : null, null],
            assign_to_id: [this.ticket ? (this.ticket.assigned_to ? this.ticket.assigned_to.text_id : null) : null, Validators.required],
            ticket_category_id: [
                this.ticket ? (this.ticket.ticket_category ? this.ticket.ticket_category.id : null) : null,
                Validators.required
            ],
            customer_request_id: [this.ticket ? this.ticket.customer_request_id : null, null],
            channel: [this.ticket ? this.ticket.channel : null, null],
        }, {
            updateOn: 'blur',
            floatLabel: 'always',
            hideRequired: false,
        });

        this.service.getAllUsers().subscribe((res: IResponse) => {
            this.users = res.data;
        });

        this.service.getMe().subscribe((res: IResponse) => {
            this.form.controls.assign_to_id.setValue(res.data.text_id);
        });

        this.service.getAllTicketCategory().subscribe((res: IResponse) => {
            this.categories = res.data;
        });
        this.refreshAllRequest();
    }

    closeDialog() {
        this.dialogRef.close(null);
    }

    onFormSubmit() {
        if (this.form.valid) {

            const data = this.form.getRawValue();
            data['account_text_id'] = this.data['account_id'];
            if (this.ticket) {
                this.service.updateTicket(this.ticket.text_id, data).subscribe((res: IResponse) => {
                    if (res.success) {
                        this.dialogRef.close(res.data);
                    }
                });
            } else {
                this.service.createTicket(data).subscribe((res: IResponse) => {
                    if (res.success) {
                        this.dialogRef.close(res.data);
                    }
                });
            }
        }
    }

    createCustomerRequest() {
    }

    refreshAllRequest() {
        this.ticketService.getAllCustomerRequest().subscribe((res: IResponse) => {
            this.customerRequests = res.data;
        });
    }

}
