<div class="dv-content">
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
        <mat-card>
            <mat-card-header>
                <mat-card-title *ngIf="!ticket">Create ticket</mat-card-title>
                <mat-card-title *ngIf="ticket">Update ticket</mat-card-title>
            </mat-card-header>

            <mat-card-content>

                <div>
                    <mat-label>Title</mat-label>
                    <mat-form-field appearance="outline">

                        <input matInput placeholder="Title" [formControl]="form.controls['title']">
                        <mat-hint class="text-danger"
                                  *ngIf="form.controls['title'].invalid && form.controls['title'].touched && form.controls['title'].errors.required">
                            Title cannot be blank.
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div>

                    <mat-label>Ticket description</mat-label>
                    <editor
                            [formControl]="form.controls['content']"
                            [init]="{
                                        base_url: '/tinymce',
                                        suffix: '.min',
                                        plugins: 'link image preview media table emoticons textcolor code searchreplace',
                                        toolbar: 'formatselect fontsizeselect fontselect forecolor backcolor | bold italic underline strikethrough subscript superscript |alignleft aligncenter alignright alignjustify | link unlink image media emoticons | hr | bullist numlist indent outdent blockquote code table | undo redo searchreplace | removeformat',
                                        menubar: 'false'

                            }">
                    </editor>
                    <mat-hint class="text-danger" *ngIf="form.controls['content'].invalid && form.controls['content'].touched && form.controls['content'].errors.required">
                    </mat-hint>
                </div>
                <div>
                    <mat-label>Assign to</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select [formControl]="form.controls['assign_to_id']">
                            <mat-option *ngFor="let user of users" [value]="user.text_id">{{user.full_name }} - {{user.email}}</mat-option>
                        </mat-select>
                        <mat-error>Assignee is required</mat-error>
                    </mat-form-field>

                </div>
                <div>
                    <mat-label>Category</mat-label>
                   <mat-form-field appearance="outline">
                       <mat-select [formControl]="form.controls['ticket_category_id']">
                           <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</mat-option>
                       </mat-select>
                       <mat-error>
                           Category is required
                       </mat-error>
                   </mat-form-field>
                </div>

                <div>
                    <mat-label>Channel</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select [formControl]="form.controls['channel']">
                            <mat-option *ngFor="let channel of ['', 'email', 'chat', 'call', 'other']" [value]="channel">{{ allChannel[channel] }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1 flex-shirk-1">
                            <mat-label>Customer Request</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select [formControl]="form.controls['customer_request_id']">
                                    <mat-option *ngFor="let cr of customerRequests" [value]="cr.id">{{cr.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                </div>


            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-button (click)="closeDialog()">Close</button>
                <button mat-raised-button color="primary" type="submit">Create</button>

            </mat-card-actions>
        </mat-card>
    </form>

</div>


