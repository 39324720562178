import {Component, OnInit} from '@angular/core';
import {MultiLanguageService} from '../shared/translate/multiLanguageService';
import {DcmClientService} from '../dws/dcm/dcm-client.service';
import {FireBaseMessagingService} from '../shared/firebase-messaging.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    prefixName = 'home';
    permissionChecked = true;

    constructor(
        public multiLanguageService: MultiLanguageService,
        // public translateService: TranslateService,
        private dcmService: DcmClientService,
        private firebaseMessagingService: FireBaseMessagingService
    ) {
    }

    ngOnInit() {
        this.multiLanguageService.onSetupMultiLanguage(this.prefixName);
        // this.translateService.get(prefixName + '.title' ).subscribe((res: string) => {
        //   console.log(res);
        // });

        this.permissionChecked = this.firebaseMessagingService.isNotificationChecked();

        this.dcmService.onMessageReceivedSubject.subscribe((value) => {
            console.log('home component on message');
            console.log(value);

        });
    }

    requestPermission() {
        this.firebaseMessagingService.requestPermission().subscribe(token => {
            console.log(token);
            this.permissionChecked = this.firebaseMessagingService.isNotificationChecked();
        }, err => {
            console.log(err);
            this.permissionChecked = this.firebaseMessagingService.isNotificationChecked();
        });
    }
}
