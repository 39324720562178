
export class MessageGroup {
    type: string;
    date: string;
    name: string;
    messages = [];
    avatar: string;
    userType: string;
    messageType: string;

    addMessage(msg) {
        if (this.messages.length === 0) {
            this.date = msg.created_at;
            this.name = msg.user.full_name;
            if (msg.user.is_me) {
                this.type = 'me';
            } else {
                this.type = 'other';
            }
            this.userType = msg.user_type;
            this.avatar = msg.user.avatar;
            if (!this.avatar) {
                this.avatar = '/assets/images/users/default-avatar.png';
            }
        }
        this.messages.push(msg);
    }
}
