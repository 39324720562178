<mat-nav-list class="list-horizontal">
    <mat-list-item (click)="onChangeLanguage('en')">
        <span *ngIf="multiLanguageService.currentLanguage() == 'en'; else otherEN" style="text-decoration: underline;">EN</span>
        <ng-template #otherEN>
            EN
        </ng-template>
    </mat-list-item>
    |
    <mat-list-item (click)="onChangeLanguage('vn')">
        <span *ngIf="multiLanguageService.currentLanguage() == 'vn'; else otherVN" style="text-decoration: underline;">VI</span>
        <ng-template #otherVN>
            VI
        </ng-template>
    </mat-list-item>
</mat-nav-list>
